import React, { useRef, useState, useEffect, useMemo } from "react";
import { Popover, makeStyles } from "@material-ui/core";

import Dropdown from "../dropdown";
import List from "./List";
import { useControlled } from "../utils";

export interface OptionType {
  label: string;
  value: string | number;
  [key: string]: any;
}

const useStyles = makeStyles((theme) => ({
  noOptions: {
    color: theme.palette.text.secondary,
    padding: "14px 16px",
  },
}));

export interface ProSelectProps {
  label?: string;
  preLabel?: string;
  isSearch?: boolean;
  value?: string | number;
  startIcon?: React.ReactNode;
  options?: OptionType[];
  renderOption?: (option: OptionType) => React.ReactNode;
  onChange?: (value: string | number) => void;
}

const Select: React.FC<ProSelectProps> = ({
  label,
  preLabel,
  isSearch,
  value: defaultValue,
  startIcon,
  options = [],
  onChange,
  renderOption,
}) => {
  const classes = useStyles();
  const textRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useControlled({
    controlled: defaultValue,
    default: "",
  });

  const selected = useMemo(() => {
    return (
      (options &&
        options.length !== 0 &&
        value !== undefined &&
        options.find((item: any) => item.value === value)) ||
      null
    );
  }, [value, options]);

  const [, refresh] = useState(1);

  useEffect(() => {
    refresh(0);
  }, []);

  function handleChange(option: OptionType) {
    setOpen(false);
    setValue(option.value);
    onChange && onChange(option.value);
  }

  function handleClear() {
    setValue("");
    onChange && onChange("");
  }

  return (
    <div>
      <div ref={textRef}>
        <Dropdown
          preLabel={preLabel}
          label={label}
          value={(selected && selected.label) || ""}
          startIcon={startIcon}
          open={open}
          onClick={() => setOpen(true)}
          onClear={handleClear}
        />
      </div>
      {open && textRef && textRef.current && (
        <Popover
          open={true}
          anchorEl={textRef.current}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          onClose={() => setOpen(false)}
        >
          {options.length === 0 ? (
            <div className={classes.noOptions}>No Options</div>
          ) : (
            <List
              options={options}
              isSearch={isSearch}
              selected={selected ? selected.value : ""}
              onChange={handleChange}
            />
          )}
        </Popover>
      )}
    </div>
  );
};

export default Select;
