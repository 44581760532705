/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useCallback } from "react";
import ReactDOM from "react-dom";
import { Grid, TextField, MenuItem, makeStyles } from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { Moment } from "moment";

import { TimeFilterProps, OptionType, ValueType } from "./interface";
import { getStartAndEndTime } from "./utils";

export type { TimeFilterProps, OptionType, ValueType };

const useStyles = makeStyles(() => ({
  select: {
    background: "white !important",
  },
}));

const options = [
  { value: "all", label: "All" },
  { value: "oneHour", label: "Last Hour" },
  { value: "today", label: "Last Day" },
  { value: "oneWeek", label: "Last Week" },
  { value: "oneMonth", label: "Last Month" },
  { value: "oneYear", label: "Last Year" },
  { value: "customized", label: "Custom" },
];

function useControlled<T>({
  controlled,
  default: defaultProp,
}: {
  controlled?: T;
  default: T;
}) {
  const { current: isControlled } = useRef(controlled !== undefined);
  const [valueState, setValue] = useState(defaultProp);
  const value = isControlled ? controlled : valueState;
  const setValueIfUncontrolled = useCallback((newValue) => {
    if (!isControlled) {
      setValue(newValue);
    }
  }, []);
  return [value, setValueIfUncontrolled];
}

const TimeFilter: React.FC<TimeFilterProps> = (props) => {
  const {
    defaultValue = {
      startDateTime: null,
      endDateTime: null,
      dateType: "all",
    },
    value,
    size,
    label = "",
    anchorRef,
    classes,
    className,
    style,
    startAdornment,
    onChange,
  } = props;

  const styles = useStyles();

  const [, refresh] = useState(1);
  const [state, setState]: any = useControlled<ValueType>({
    controlled: value,
    default: defaultValue,
  });
  const textRef = useRef<HTMLInputElement>(null);
  const [menuWidth, setMenuWidth] = useState(0);

  useEffect(() => {
    if (
      textRef !== null &&
      textRef.current !== null &&
      startAdornment !== undefined
    ) {
      const rect = textRef.current.getBoundingClientRect();
      setMenuWidth(rect.width);
    }
  }, [textRef, startAdornment]);

  function handleChangeDate(key: string) {
    return function (date: Moment | null) {
      if (date) {
        date.seconds(0);
        date.milliseconds(0);
      }
      const newState: any = {
        ...state,
        [key]: date,
      };
      setState(newState);
      onChange && onChange(newState);
    };
  }

  function handleChangeType(newType: string) {
    const { startTime, endTime } = getStartAndEndTime(
      newType,
      state.startDateTime,
      state.endDateTime
    );
    const newState = {
      startDateTime: startTime,
      endDateTime: endTime,
      dateType: newType,
    };
    setState(newState);
    onChange && onChange(newState);
  }

  function renderDateTime() {
    if (state.dateType !== "customized") {
      return null;
    }

    return (
      <Grid container spacing={3}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Grid item>
            <KeyboardDatePicker
              label="Start date"
              format="YYYY/MM/DD"
              size={size}
              clearable
              value={state.startDateTime || null}
              onChange={handleChangeDate("startDateTime")}
              keyboardIcon={<CalendarTodayIcon />}
            />
          </Grid>
          <Grid item>
            <KeyboardDatePicker
              label="End date"
              format="YYYY/MM/DD"
              size={size}
              clearable
              value={state.endDateTime || null}
              onChange={handleChangeDate("endDateTime")}
              keyboardIcon={<CalendarTodayIcon />}
            />
          </Grid>
          <Grid item>
            <KeyboardTimePicker
              label="Start time"
              format="HH:mm:ss"
              size={size}
              clearable
              value={state.startDateTime || null}
              onChange={handleChangeDate("startDateTime")}
              keyboardIcon={<AccessTimeIcon />}
            />
          </Grid>
          <Grid item>
            <KeyboardTimePicker
              label="End time"
              size={size}
              format="HH:mm:ss"
              clearable
              value={state.endDateTime || null}
              onChange={handleChangeDate("endDateTime")}
              keyboardIcon={<AccessTimeIcon />}
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </Grid>
    );
  }

  useEffect(() => {
    refresh(0);
  }, []);

  return (
    <React.Fragment>
      <TextField
        size={size}
        ref={textRef}
        fullWidth
        select
        value={state.dateType}
        classes={classes}
        className={className}
        label={label}
        InputProps={{
          startAdornment: startAdornment ? (
            <div style={{ display: "flex", marginLeft: -4, marginRight: 4 }}>
              {startAdornment}
            </div>
          ) : undefined,
        }}
        SelectProps={{
          classes: { select: styles.select },
          MenuProps: {
            style: {
              marginTop: size === "medium" ? 56 : 40,
              marginLeft: startAdornment === undefined ? 0 : -40,
            },
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          },
        }}
        variant="outlined"
        style={{ background: "#fff", ...style }}
        onChange={(e) => {
          handleChangeType(e.target.value);
        }}
      >
        {options.map((option) => (
          <MenuItem
            style={{ width: startAdornment ? menuWidth : "auto" }}
            key={option.value}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      {anchorRef &&
        anchorRef.current &&
        ReactDOM.createPortal(renderDateTime(), anchorRef.current)}
    </React.Fragment>
  );
};

export default TimeFilter;
