import React from "react";
import * as ReactDOM from "react-dom";
import {
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  actions: {
    padding: theme.spacing(1),
  },
  footer: {
    margin: theme.spacing(0, 1),
  },
  button: {
    textTransform: "none",
  },
}));

export interface ModalProps {
  open?: boolean;
  title?: string;
  fullWidth?: boolean;
  maxWidth?: "sm" | "md" | "lg" | "xs" | "xl";
  suffix?:string;
  extraText?: string;
  extraInfo?: React.ReactNode;
  hideCancel?: boolean;
  hideActions?: boolean;
  okText?: string;
  cancelText?: string;
  clearText?: string;
  content?: React.ReactNode;
  dividers?: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  onClear?: () => void;
  dialogClasses?: Record<string, React.CSSProperties>;
}

const OriginModal: React.FC<ModalProps> = ({
  open = true,
  title,
  fullWidth = true,
  maxWidth = "sm",
  dividers = true,
  suffix,
  extraText,
  extraInfo,
  hideCancel,
  hideActions,
  content,
  okText,
  cancelText,
  clearText,
  children,
  onClose,
  onConfirm,
  onClear,
  dialogClasses,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onClose={onClose}
      classes={dialogClasses}
    >
      <DialogTitle className={classes.header}>
        <Grid container justify="space-between" alignItems="center">
          <Grid container direction="row" alignItems="center">
            <Typography variant="h6">{title}</Typography>
            <Typography
              style={{
                paddingLeft: '6px',
                paddingTop: '4px',
                fontSize: '10px',
                fontWeight: 'bold',
              }}
              color="primary"
            >
              {suffix}
            </Typography>
          </Grid>
          <IconButton
            aria-label="close"
            onClick={onClose}
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent dividers={dividers} className={classes.content}>
        {content || children}
      </DialogContent>
      {!hideActions && (
        <DialogActions className={classes.actions}>
          <Grid
            className={classes.footer}
            container
            alignItems="center"
            justify="space-between"
          >
            <Grid item>
              {extraInfo || (
                <Typography variant="body2">{extraText}</Typography>
              )}
            </Grid>
            <Grid item>
              {onClear && (
                <Button
                  className={classes.button}
                  onClick={onClear}
                  style={{ marginRight: 8 }}
                  color="primary"
                >
                  {clearText || "Clear"}
                </Button>
              )}
              {!hideCancel && (
                <Button
                  className={classes.button}
                  onClick={onClose}
                  style={{ marginRight: 8 }}
                  color="primary"
                >
                  {cancelText || "Cancel"}
                </Button>
              )}
              <Button
                className={classes.button}
                autoFocus
                onClick={onConfirm}
                color="primary"
                variant="contained"
              >
                {okText || "Done"}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      )}
    </Dialog>
  );
};

export function confirm(props: ModalProps) {
  const { onClose, onConfirm } = props;

  const div = document.createElement("div");
  document.body.appendChild(div);

  function handleClose() {
    destroy();
    onClose && onClose();
  }

  function handleConfirm() {
    destroy();
    onConfirm && onConfirm();
  }

  function destroy() {
    const umount = ReactDOM.unmountComponentAtNode(div);
    if (umount && div.parentNode) {
      div.parentNode.removeChild(div);
    }
  }

  setTimeout(() => {
    ReactDOM.render(
      <OriginModal
        {...props}
        onClose={handleClose}
        onConfirm={handleConfirm}
      />,
      div
    );
  });
}

const Modal = OriginModal as typeof OriginModal &
  Record<string, (props: ModalProps) => void>;
Modal.confirm = confirm;

export default Modal;
