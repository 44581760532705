import React from 'react';
import {Typography, Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import noResult from './no_results_robot_2.png';

const useStyles = makeStyles({
  root: {
    lineHeight: '50px',
    paddingTop: '200px',
  },
});

const NoData = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      justify="center"
      direction="column"
      alignItems="center"
      className={classes.root}
    >
      <Grid>
        <img src={noResult} alt="No Data" />
      </Grid>
      <Grid>
        <Typography variant="h6" color="primary">
          Turing Robot has found no records.
        </Typography>
      </Grid>
      <Grid>
        <Typography>It encourages you to try again.</Typography>
      </Grid>
    </Grid>
  );
};

export default NoData;
