import { useEffect, useState } from "react";

import { RequestData, UseFetchProps, UseFetchDataAction } from "./interface";

const useFetchData = <T extends RequestData<any>>(
  getData:
    | undefined
    | ((params?: { pageSize: number; current: number }) => Promise<T>),
  defaultData: undefined | any[],
  options: UseFetchProps
): UseFetchDataAction => {
  const [list, setList] = useState(defaultData || []);
  const [pageInfo, setPageInfo] = useState({
    current: 0,
    pageSize: options.pageInfo.pageSize || 10,
    total: 0,
  });

  const fetchList = async () => {
    if (!getData) {
      return [];
    }

    const { pageSize, current } = pageInfo;
    const pageParams = { pageSize, current: current + 1 };
    const { data = [], total = 0 } = (await getData(pageParams)) || {};
    setList(data);
    if (pageInfo.total !== total) {
      setPageInfo({
        ...pageInfo,
        total,
      });
    }
  };

  useEffect(() => {
    fetchList();
  }, [pageInfo.current]);

  useEffect(() => {
    fetchList();
  }, [pageInfo.pageSize]);

  return {
    dataSource: list,
    setDataSource: setList,
    pageInfo,
    setPageInfo: async (info) => {
      setPageInfo({
        ...pageInfo,
        ...info,
      });
    },
  };
};

export default useFetchData;
