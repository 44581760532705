import React from "react";
import moment, { Moment } from "moment";
import clsx from "clsx";
import { Grid, IconButton, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "1px 0",
  },
  button: {
    width: 36,
    height: 36,
    padding: 0,
  },
  start: {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  },
  end: {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  },
  single: {
    borderRadius: "50%",
  },
  filled: {
    background: theme.palette.primary.main,
    "&:hover": {
      background: theme.palette.primary.dark,
    },
  },
  range: {
    background: theme.palette.primary.light,
  },
  buttonText: {},
  contrast: {
    color: theme.palette.primary.contrastText,
  },
  today: {
    border: "1px solid rgba(0, 0, 0, 0.6)",
  },
}));

const today = moment().endOf("day");

interface DayProps {
  day: Moment | null;
  rangeValid?: [Moment | null, Moment | null];
  startDate: Moment | null;
  endDate: Moment | null;
  onClick: (d: Moment) => void;
}

const Day: React.FC<DayProps> = ({
  day,
  rangeValid,
  startDate,
  endDate,
  onClick,
}) => {
  const classes = useStyles();

  if (!day)
    return (
      <Grid item className={classes.root}>
        <Grid className={classes.button} />
      </Grid>
    );

  const outerStart =
    startDate &&
    (day.day() === 0 || day.isSame(startDate, "d") || day.date() === 1);
  const outerEnd =
    startDate &&
    (day.day() === 6 ||
      day.clone().add(1, "days").date() === 1 ||
      (endDate && day.isSame(endDate, "d")) ||
      (!endDate && day.isSame(startDate, "d")));
  const inRange =
    startDate &&
    ((endDate && day.isBetween(startDate, endDate, "day", "[]")) ||
      (!endDate && day.isSame(startDate, "d")));
  const isEnd = day.isSame(startDate, "d") || day.isSame(endDate, "d");
  const isToday = day.isSame(moment(), "d");

  const inRangeValid = () => {
    let flag = false;
    if (rangeValid) {
      if (rangeValid[0] && rangeValid[1]) {
        flag = !day.isBetween(rangeValid[0], rangeValid[1], "day", "[]");
      } else if (rangeValid[0]) {
        flag = !day.isSameOrAfter(rangeValid[0]);
      } else if (rangeValid[1]) {
        flag = !day.isSameOrBefore(rangeValid[1]);
      }
    }
    return flag;
  };

  return (
    <Grid
      item
      className={clsx(
        classes.root,
        outerStart && classes.start,
        outerEnd && classes.end,
        inRange && classes.range
      )}
    >
      <IconButton
        onClick={() => onClick(day)}
        disabled={day.isAfter(today) || inRangeValid()}
        className={clsx(
          classes.button,
          isEnd && classes.filled,
          isToday && classes.today
        )}
      >
        <Typography
          variant="body2"
          color={
            day.isAfter(today) || inRangeValid() ? "inherit" : "textPrimary"
          }
          className={clsx(classes.buttonText, isEnd && classes.contrast)}
        >
          {day.format("D")}
        </Typography>
      </IconButton>
    </Grid>
  );
};

export default Day;
