import React, { useState, useLayoutEffect } from "react";
import {
  Paper,
  Table as MaterialTable,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Typography,
  makeStyles,
  Backdrop,
  TableSortLabel,
  CircularProgress,
} from "@material-ui/core";

import Cell from "./Cell";
import { TableProps, KV } from "./interface";
import NoData from "./NoData";

export type { TableProps };

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
  },
  head: {
    background: theme.palette.background.default,
    borderTop: `1px solid ${theme.palette.divider}`,
    "& th": {
      padding: theme.spacing(1, 2),
    },
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
}));

const defaultNoData = <NoData />;

const Table = <T extends Record<string, any>>(props: TableProps<T>) => {
  const {
    loading = false,
    hover = true,
    dataSource = [],
    renderNoData = defaultNoData,
    columns = [],
    pagination,
    rowsPerPageOptions = [5, 10, 20],
    rowClassName,
    className,
    style,
    onChange,
    onRowClick,
    onSort,
  } = props;

  const classes = useStyles();

  const [sortState, setSortState] = useState<KV>({});

  useLayoutEffect(() => {
    const initState: KV = {};
    columns.forEach(
      (column, index) =>
        (initState[String(index)] = column.sortDirection || "desc")
    );
    setSortState(initState);
  }, []);

  function handleSort(
    key: string | undefined,
    index: number,
    oldValue: string
  ) {
    const newValue = oldValue === "asc" ? "desc" : "asc";
    const obj: KV = {};
    Object.keys(sortState).forEach(
      (key) => (obj[key] = sortState[key] || "desc")
    );
    setSortState({
      ...obj,
      [String(index)]: newValue,
    });
    onSort && onSort(key || index, newValue);
  }

  function handleRowClick(record: T, index: number) {
    onRowClick && onRowClick(record, index);
  }

  return (
    <Paper className={classes.root}>
      <MaterialTable className={className} style={style}>
        <TableHead className={classes.head}>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell
                key={index}
                style={{
                  width: column.width || "",
                  paddingRight:
                    column.paddingRight === undefined
                      ? ""
                      : column.paddingRight,
                }}
              >
                {column.sortable ? (
                  <TableSortLabel
                    active={true}
                    direction={sortState[String(index)] || "desc"}
                    onClick={() => {
                      handleSort(
                        column.key || column.dataIndex,
                        index,
                        sortState[String(index)]
                      );
                    }}
                  >
                    {column.title}
                  </TableSortLabel>
                ) : (
                  column.title
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {dataSource.length > 0 && (
          <TableBody>
            {dataSource.map((record: T, index: number) => (
              <TableRow
                key={index}
                hover={hover}
                className={rowClassName}
                onClick={() => handleRowClick(record, index)}
                style={{ cursor: hover ? "pointer" : "auto" }}
              >
                {columns.map((column, index) => (
                  <TableCell
                    key={index}
                    style={{
                      paddingRight:
                        column.paddingRight === undefined
                          ? ""
                          : column.paddingRight,
                    }}
                  >
                    <Cell node={column} record={record} index={index} />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        )}
      </MaterialTable>
      {dataSource.length === 0 && !loading && renderNoData}
      {pagination && (
        <TablePagination
          component="div"
          count={pagination.total}
          page={pagination.current - 1}
          rowsPerPage={pagination.pageSize}
          rowsPerPageOptions={rowsPerPageOptions}
          onChangePage={(_, newPage) => {
            onChange && onChange(newPage + 1, pagination.pageSize);
          }}
          onChangeRowsPerPage={(event) => {
            onChange && onChange(1, parseInt(event.target.value));
          }}
        />
      )}
      {loading && (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Paper>
  );
};

export default Table;
