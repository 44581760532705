import React, { useState, useEffect } from "react";
import { List, ListItem, TextField, makeStyles } from "@material-ui/core";
import { OptionType } from "./index";

interface ProListProps {
  selected?: string | number;
  isSearch?: boolean;
  options: OptionType[];
  onChange: (v: OptionType) => void;
}

const useStyles = makeStyles((theme) => ({
  filterInput: {
    margin: 10,

    "& .MuiOutlinedInput-input": {
      padding: "10px 7px",
    },
  },

  noOptions: {
    color: theme.palette.text.secondary,
    padding: "14px 16px",
  },
}));

const ProList: React.FC<ProListProps> = ({
  options,
  isSearch,
  selected,
  onChange,
}) => {
  const classes = useStyles();
  const [option, setOption] = useState([]);

  useEffect(() => {
    setOption(options);
  }, [options]);

  const handleInput = (e) => {
    const value = e.target.value;
    if (value) {
      let arr = [];
      options.forEach((item) => {
        if (item.label.includes(value)) {
          arr.push(item);
        }
      });
      setOption(arr);
    } else {
      setOption(options);
    }
  };

  return (
    <div style={{ maxHeight: isSearch ? 400 : 300 }}>
      {isSearch && (
        <TextField
          variant="outlined"
          className={classes.filterInput}
          onChange={handleInput}
        />
      )}
      {option.length === 0 ? (
        <div className={classes.noOptions}>No Options</div>
      ) : (
        <List style={{ maxHeight: 300 }}>
          {option.map((item, idx) => (
            <ListItem
              selected={selected === item.value}
              key={idx}
              onClick={() => onChange(item)}
              button
            >
              {item.label}
            </ListItem>
          ))}
        </List>
      )}
    </div>
  );
};

export default ProList;
