
    import React from 'react';
    import { dynamic } from 'dumi';
    import { Link, AnchorLink } from 'dumi/theme';
    import API from 'dumi-theme-default/es/builtins/API.js';
import Alert from 'dumi-theme-default/es/builtins/Alert.js';
import Badge from 'dumi-theme-default/es/builtins/Badge.js';
import Example from 'dumi-theme-default/es/builtins/Example.js';
import Previewer from 'dumi-theme-default/es/builtins/Previewer.js';
import SourceCode from 'dumi-theme-default/es/builtins/SourceCode.js';
    import DUMI_ALL_DEMOS from '@@/dumi/demos';

    

    export default (props) => {
      // scroll to anchor after page component loaded
      React.useEffect(() => {
        if (props?.location?.hash) {
          AnchorLink.scrollToAnchor(decodeURIComponent(props.location.hash.slice(1)));
        }
      }, []);

      return (
        <>
          
          
        </>
      );
  }