import React, { useRef, useState, useEffect } from "react";
import {
  Popper,
  Paper,
  TextField,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import ClearIcon from "@material-ui/icons/Clear";

import List from "./List";
import { useControlled } from "../utils";

export interface OptionType {
  label: string;
  value: string | number;
  [key: string]: any;
}

export interface ProAutocompleteProps {
  label?: string;
  initLabel?: string;
  placeholder?: string;
  value?: string | number;
  noArrow?: boolean;
  startAdornment?: React.ReactNode;
  options?: OptionType[];
  request?: (keyword: string) => Promise<void>;
  renderOption?: (option: OptionType) => React.ReactNode;
  onChange?: (value: string | number) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  input: {
    width: "100%",
    "& input": {
      paddingTop: 6,
      paddingBottom: 6,
      lineHeight: 16,
      height: 16,
      paddingLeft: 8,
      fontSize: 14,
    },
    "&>div": {
      paddingTop: "2px !important",
      paddingBottom: "2px !important",
    },
    "& fieldset": {
      borderRadius: 16,
      borderWidth: "1px !important",
    },
  },
  endAdornment: {
    top: "calc(50% - 14px)",
    right: 8,
    position: "absolute",
  },
  clearButton: {
    marginTop: 1,
  },
  noOptions: {
    color: theme.palette.text.secondary,
    padding: "14px 16px",
  },
  popper: {
    zIndex: theme.zIndex.modal + 1,
  },
}));

const ProAutocomplete: React.FC<ProAutocompleteProps> = ({
  label,
  initLabel,
  placeholder,
  noArrow = true,
  request,
  startAdornment,
  value: defaultValue,
  options = [],
  onChange,
  renderOption,
}) => {
  const classes = useStyles();
  const pathname = useRef("");
  const timer = useRef(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const textRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useControlled({
    controlled: defaultValue,
    default: "",
  });
  const [inputValue, setInputValue] = useState("");

  function setInputLabel(v: string) {
    if (options && options.length !== 0) {
      const option = options.find((item: any) => item.value === v);
      setInputValue((option && option.label) || "");
    } else {
      try {
        const storageOptions = JSON.parse(
          sessionStorage.getItem(
            `turing-ui-autocomplete-options-${pathname.current}`
          ) || ""
        );
        if (storageOptions && storageOptions.length > 0) {
          const option = storageOptions.find((item: any) => item.value === v);
          setInputValue((option && option.label) || "");
        }
      } catch (e) {}
    }
  }

  useEffect(() => {
    pathname.current = window.location.pathname;
    if (value !== undefined && !initLabel) {
      setInputLabel(value);
    } else if (value !== undefined && initLabel) {
      setInputValue(initLabel);
    }
  }, [initLabel]);

  useEffect(() => {
    return () => {
      if (options.length > 0) {
        sessionStorage.setItem(
          `turing-ui-autocomplete-options-${pathname.current}`,
          JSON.stringify(options)
        );
      } else if (value && initLabel) {
        sessionStorage.setItem(
          `turing-ui-autocomplete-options-${pathname.current}`,
          JSON.stringify([{ value, label: initLabel }])
        );
      }
    };
  }, [options, value, initLabel]);

  useEffect(() => {
    if (!value) {
      setInputValue("");
    }
  }, [value]);

  const [, refresh] = useState(1);

  useEffect(() => {
    refresh(0);
  }, []);

  function handleSelect(option: OptionType) {
    setOpen(false);
    setValue(option.value);
    onChange && onChange(option.value);
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    setOpen(true);
    setInputValue(e.target.value);
    if (!e.target.value) {
      onChange && onChange("");
    }
    const cacheValue = e.target.value;
    if (request) {
      if (timer.current) {
        clearTimeout(timer.current);
      }

      timer.current = window.setTimeout(() => {
        request(cacheValue);
      }, 500);
    }
  }

  function handleClear() {
    setValue("");
    setInputValue("");
    onChange && onChange("");
  }

  function handleBlur() {
    setFocused(false);
    setOpen(false);
    setInputLabel(value);
  }

  function handleFocus() {
    setFocused(true);
    if (inputValue) {
      setOpen(true);
    }
  }

  function handleMouseEnter() {
    setFocused(true);
  }

  function handleMouseLeave() {
    setFocused(false);
  }

  function handleDropdown() {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }

  return (
    <>
      <div ref={textRef} className={classes.root}>
        <TextField
          className={classes.input}
          label={label}
          placeholder={placeholder}
          value={inputValue}
          size="small"
          variant="outlined"
          onChange={handleInputChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          InputProps={{
            style: { paddingRight: !noArrow ? 65 : 32, paddingLeft: 8 },
            startAdornment: startAdornment,
            endAdornment: (
              <div className={classes.endAdornment}>
                {focused && value && (
                  <IconButton
                    onMouseDown={handleClear}
                    size="small"
                    className={classes.clearButton}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                )}
                {!noArrow && (
                  <IconButton onMouseDown={handleDropdown} size="small">
                    <ArrowDropDown />
                  </IconButton>
                )}
              </div>
            ),
          }}
          inputProps={{
            ref: inputRef,
          }}
        />
      </div>
      {open && textRef && textRef.current && (
        <Popper
          style={{
            width: textRef ? textRef.current.clientWidth : "auto",
            marginTop: 4,
          }}
          className={classes.popper}
          anchorEl={textRef.current}
          open
        >
          <Paper>
            {options.length === 0 ? (
              <div className={classes.noOptions}>No Options</div>
            ) : (
              <List
                options={options}
                selected={value}
                renderOption={renderOption}
                onSelect={handleSelect}
              />
            )}
          </Paper>
        </Popper>
      )}
    </>
  );
};

export default ProAutocomplete;
