import React, { useEffect, useRef } from "react";
import HlsPlayer from "hls.js";
import "./index.css";

const containerStyle: React.CSSProperties = {
  position: "absolute",
  top: "0",
  width: "100%",
  height: "100%",
};

export interface HLSPlayerProps {
  paused?: boolean;
  muted?: boolean;
  url: string;
  onCanPlay?: Function;
  onError?: Function;
  onTimeUpdate?: Function;
}

interface HLSPlayerType {
  loadSource: (url: string) => void;
  attachMedia: (v: HTMLVideoElement | null) => void;
  destroy: Function;
  on: Function;
  startLoad: Function;
  recoverMediaError: Function;
}

const HLSPlayer: React.FC<HLSPlayerProps> = ({
  paused,
  muted,
  url,
  onCanPlay,
  onError,
  onTimeUpdate,
}) => {
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const player = useRef<HTMLVideoElement>(null);
  const hls = useRef<HLSPlayerType>();
  const reloadSourceTimes = useRef<number>(0);
  const recoverMediaTimes = useRef<number>(0);
  const retryTimer = useRef<number | undefined>();

  useEffect(() => {
    if (HlsPlayer.isSupported()) {
      hls.current = new HlsPlayer({
        startFragPrefetch: true,
        liveSyncDuration: 2,
        liveMaxLatencyDuration: 4,
        manifestLoadingMaxRetry: 4,
        fragLoadingMaxRetry: 0,
        fragLoadingTimeOut: 2000,
        xhrSetup: function (xhr: any) {
          xhr.withCredentials = true; // send cookies
        },
      }) as HLSPlayerType;
      hls.current.on(HlsPlayer.Events.ERROR, handleError);
      hls.current.loadSource(url);
      hls.current.attachMedia(player.current);
      hls.current.on(HlsPlayer.Events.MANIFEST_PARSED, handleManifestParsed);
      if (onTimeUpdate) {
        player?.current?.addEventListener("timeupdate", function () {
          onTimeUpdate(player.current?.currentTime);
        });
      }
    } else if (
      player.current &&
      player.current.canPlayType("application/vnd.apple.mpegurl")
    ) {
      player.current.src = url;
      player.current.addEventListener("loadedmetadata", () => {
        player.current && player.current.play();
      });
    }

    return () => {
      if (player.current) {
        player.current.pause();
      }
      if (hls.current) {
        hls.current.destroy();
      }
    };
  }, []);

  function handleManifestParsed(event: any, data: any) {
    player.current && player.current.play();
  }

  useEffect(() => {
    if (player && player.current && typeof paused === "boolean") {
      if (paused) {
        player.current.pause();
      } else {
        player.current.play();
      }
    }
  }, [paused]);

  // Set minimum interval for burst retries triggered by continuous errors
  function setRetryTimer(callback: Function, errType: string) {
    if (retryTimer.current) {
      return;
    }
    retryTimer.current = window.setTimeout(() => {
      callback();
      if (errType === HlsPlayer.ErrorTypes.NETWORK_ERROR) {
        reloadSourceTimes.current += 1;
      }
      if (errType === HlsPlayer.ErrorTypes.MEDIA_ERROR) {
        recoverMediaTimes.current += 1;
      }
      retryTimer.current = undefined;
    }, 500);
  }

  function handleError(err: string, errData: any) {
    console.debug(
      errData,
      `reloadSourceTimes: ${reloadSourceTimes.current}`,
      `recoverMediaTimes: ${recoverMediaTimes.current}`
    );
    const errDetail = errData.details || "";
    const errType = errData.type || "";
    const fatal = !!errData.fatal;
    if (reloadSourceTimes.current >= 5 || recoverMediaTimes.current >= 11) {
      return onError && onError(err, errData, hls.current, HlsPlayer, true);
    }
    switch (errDetail) {
      case HlsPlayer.ErrorDetails.MANIFEST_LOAD_ERROR:
        if (fatal) {
          console.debug("hls loadSource");
          setRetryTimer(() => hls.current?.loadSource(url), errType);
        }
        break;
      case HlsPlayer.ErrorDetails.LEVEL_LOAD_ERROR:
      case HlsPlayer.ErrorDetails.LEVEL_LOAD_TIMEOUT:
        if (fatal) {
          console.debug("hls startLoad");
          setRetryTimer(() => hls.current?.startLoad(), errType);
        }
        break;
      case HlsPlayer.ErrorDetails.FRAG_PARSING_ERROR:
        console.debug("hls recoverMediaError");
        setRetryTimer(() => hls.current?.recoverMediaError(), errType);
        break;
      case HlsPlayer.ErrorDetails.BUFFER_STALLED_ERROR:
        // No recover function call needed. Player will handle buffer error until becoming fatal.
        recoverMediaTimes.current += 1;
        break;
      default:
        onError && onError(err, errData, hls.current, HlsPlayer);
        break;
    }
  }

  function handleCanPlay() {
    console.debug("hls video can play");
    // Apply retry limits on peak counts of continuous errors. Reset retry times everytime the video recovers.
    reloadSourceTimes.current = 0;
    recoverMediaTimes.current = 0;
    onCanPlay && onCanPlay();
  }

  return (
    <div>
      <div data-vjs-player style={containerStyle} ref={videoContainerRef}>
        <video
          ref={player}
          className="hls-player"
          muted={muted}
          src={url}
          controls={false}
          preload="auto"
          autoPlay={true}
          onCanPlay={handleCanPlay}
          onError={onError as any}
        >
          Live streaming not supported by browser. Please open with Chrome.
        </video>
      </div>
    </div>
  );
};

export default HLSPlayer;
