
    import React from 'react';
    import { dynamic } from 'dumi';
    import { Link, AnchorLink } from 'dumi/theme';
    import API from 'dumi-theme-default/es/builtins/API.js';
import Alert from 'dumi-theme-default/es/builtins/Alert.js';
import Badge from 'dumi-theme-default/es/builtins/Badge.js';
import Example from 'dumi-theme-default/es/builtins/Example.js';
import Previewer from 'dumi-theme-default/es/builtins/Previewer.js';
import SourceCode from 'dumi-theme-default/es/builtins/SourceCode.js';
    import DUMI_ALL_DEMOS from '@@/dumi/demos';

    const DumiDemo1 = React.memo(DUMI_ALL_DEMOS['tooltip-demo'].component);

    export default (props) => {
      // scroll to anchor after page component loaded
      React.useEffect(() => {
        if (props?.location?.hash) {
          AnchorLink.scrollToAnchor(decodeURIComponent(props.location.hash.slice(1)));
        }
      }, []);

      return (
        <>
          
          <React.Fragment><div className="markdown"><h2 id="tooltip"><AnchorLink to="#tooltip" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>Tooltip</h2>
<p>Tooltip</p>
<h3 id="何时使用"><AnchorLink to="#何时使用" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>何时使用</h3>
<ul>
<li>需要展示 Tooltip</li>
</ul>
<h3 id="代码演示"><AnchorLink to="#代码演示" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>代码演示</h3>
<p>简单使用：</p>
</div><Previewer {...DUMI_ALL_DEMOS['tooltip-demo'].previewerProps}><DumiDemo1 /></Previewer>
<div className="markdown"><h3 id="api"><AnchorLink to="#api" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>API</h3>
<h5 id="tooltip-1"><AnchorLink to="#tooltip-1" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>Tooltip</h5>

































































































































































<table><thead><tr><th>参数</th><th>说明</th><th>类型</th><th>默认值</th></tr></thead><tbody><tr><td>title</td><td>展示的内容</td><td>string | node</td><td>-</td></tr><tr><td>arrow</td><td>是否显示箭头</td><td>boolean</td><td>false</td></tr><tr><td>classes</td><td>重写组件样式</td><td>object</td><td>-</td></tr><tr><td>describeChild</td><td /><td>boolean</td><td>false</td></tr><tr><td>disableFocusListener</td><td /><td>boolean</td><td>false</td></tr><tr><td>disableHoverListener</td><td /><td>boolean</td><td>false</td></tr><tr><td>diableInteractive</td><td /><td>boolean</td><td>false</td></tr><tr><td>disableTouchListener</td><td /><td>boolean</td><td>false</td></tr><tr><td>enterDelay</td><td /><td>number</td><td>100</td></tr><tr><td>enterNextDelay</td><td /><td>number</td><td>0</td></tr><tr><td>enterTouchDelay</td><td /><td>number</td><td>700</td></tr><tr><td>followCursor</td><td /><td>boolean</td><td>false</td></tr><tr><td>id</td><td /><td>string</td><td>-</td></tr><tr><td>style</td><td /><td>React.CSSProperties</td><td>-</td></tr><tr><td>leaveDelay</td><td /><td>number</td><td>0</td></tr><tr><td>leaveTouchDelay</td><td /><td>number</td><td>1500</td></tr><tr><td>onClose</td><td /><td>(event: object) =&gt; void</td><td>-</td></tr><tr><td>onOpen</td><td /><td>(event: object) =&gt; void</td><td>-</td></tr><tr><td>open</td><td /><td>boolean</td><td>false</td></tr><tr><td>placement</td><td /><td>'bottom-end' | 'bottom-start' | 'bottom' | 'left-end' | 'left-start' | 'left' | 'right-end' | 'right-start' | 'right' | 'top-end' | 'top-start' | 'top'</td><td>'bottom'</td></tr><tr><td>PopperComponent</td><td /><td>elementType</td><td>Popper</td></tr><tr><td>PopperProps</td><td /><td>object</td><td>{"{"}{"}"}</td></tr><tr><td>sx</td><td /><td>object</td><td>-</td></tr><tr><td>TransitionComponent</td><td /><td>elementType</td><td>Grow</td></tr><tr><td>TransitionProps</td><td /><td>object</td><td>-</td></tr></tbody></table></div></React.Fragment>
        </>
      );
  }