import React, { useState, useContext, useMemo, useEffect, useRef } from "react";
import {
  Collapse,
  List,
  ListItem,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ExpandIcon from "@material-ui/icons/ExpandMore";
import { useLocation, NavLink } from "react-router-dom";

import MenuContext from "./contextTypes";
import { arrAdd, arrDel, flatternArray } from "./util";
import { Key, NodeType, EventDataNode, FlatternNode } from "./interface";

export interface SideMenuProps {
  data: NodeType[];
  classes?: Object;
  style?: React.CSSProperties;
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: 56,
    backgroundColor: "transparent",
    color: "#bdbdbd",
    borderLeftWidth: 4,
    borderLeftColor: "transparent",
    borderLeftStyle: "solid",
  },
  selected: {
    backgroundColor: "rgba(180, 205, 194, 0.08) !important",
    color: "white",
    borderLeftColor: theme.palette.primary.main,
  },
  label: { flex: 1 },
  expand: {
    float: "right",
  },
  expanded: {
    transform: "rotateX(180deg)",
  },
  suffix: {
    paddingLeft: '9px',
    paddingTop: '6.5px',
    fontSize: '10px',
    fontWeight: 'bold',
    color: '#7384E2',
  }
}));

const MenuItem = ({ node }: { node: FlatternNode }) => {
  const { name, path, children = [], depth, icon, exact = false, key, suffix } = node;
  const context = useContext(MenuContext);
  const location = useLocation();
  const {
    expandedKeys,
    onNodeExpand,
    hoveredMenuKey,
    setHoveredMenuKey,
    onLeafClick,
  } = context;
  const classes = useStyles();

  const active = useMemo(() => {
    if (location.pathname) {
      return location.pathname === path;
    }
    return false;
  }, [location.pathname, path]);

  const hovered = useMemo(() => {
    return hoveredMenuKey === key || location.pathname.includes(path || "");
  }, [hoveredMenuKey, key, path, location.pathname]);

  const expanded = useMemo(() => {
    return expandedKeys.includes(key);
  }, [expandedKeys, key]);

  function onExpand(event: React.MouseEvent<HTMLDivElement>) {
    onNodeExpand(event, { path, expanded, key });
  }

  function onClick(event: React.MouseEvent<HTMLDivElement>) {
    onLeafClick(event, { path, expanded, key });
  }

  function renderIcon(icon?: React.ReactElement) {
    return (
      icon &&
      React.cloneElement(icon, {
        style: {
          color: active || hovered || expanded ? "white" : "#b7b7bd",
          marginRight: 12,
        },
      })
    );
  }
  function renderLabel() {
    return (
      <ListItem
        style={{
          paddingLeft,
          height: depth === 0 ? 56 : 48,
          color: hovered || expanded ? "white" : "#b7b7bd",
        }}
        selected={active}
        classes={{
          root: classes.root,
          selected: classes.selected,
        }}
        onMouseOver={(e) => {
          setHoveredMenuKey(key);
        }}
        button
        onClick={children.length > 0 ? onExpand : onClick}
      >
        {renderIcon(icon)}
        <div style={{display: 'flex'}}>
          <Typography className={classes.label} spellCheck={false}>
            {name}
          </Typography>
          <Typography
            className={classes.suffix}
          >
            {suffix}
          </Typography>
        </div>
        {children.length > 0 && (
          <ExpandIcon
            className={`${classes.expand} ${expanded ? classes.expanded : ""}`}
          />
        )}
      </ListItem>
    );
  }

  const paddingLeft = useMemo(() => {
    return 24 + depth * 32;
  }, [depth]);

  return (
    <div>
      {children.length > 0 ? (
        renderLabel()
      ) : (
        <NavLink
          to={path || ""}
          exact={exact}
          style={{ textDecoration: "none" }}
        >
          {renderLabel()}
        </NavLink>
      )}
      {children.length > 0 && expanded && (
        <Collapse in={true}>
          {children.map((item: any, index: number) => (
            <MenuItem key={index} node={item} />
          ))}
        </Collapse>
      )}
    </div>
  );
};

const SideMenu: React.FC<SideMenuProps> = ({
  data = [],
  classes,
  className,
  style,
}) => {
  const location = useLocation();
  const dirty = useRef(false);
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
  const [hoveredMenuKey, setHoveredMenuKey] = useState<string | null>(null);

  const flatternData = useMemo(() => {
    return flatternArray(data);
  }, [data]);

  useEffect(() => {
    if (!dirty.current && flatternData && flatternData.length > 0) {
      dirty.current = true;
      flatternData.forEach((item) => {
        if ((item.children || []).some((v) => v.path === location.pathname)) {
          setExpandedKeys([item.key]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flatternData]);

  function onNodeExpand(
    e: React.MouseEvent<HTMLDivElement>,
    menuNode: EventDataNode
  ) {
    const { key, expanded } = menuNode;
    const targetExpanded = !expanded;

    let newExpandedKeys;
    if (targetExpanded) {
      newExpandedKeys = arrAdd(expandedKeys, key);
    } else {
      newExpandedKeys = arrDel(expandedKeys, key);
    }
    setExpandedKeys(newExpandedKeys);
  }

  function onLeafClick(
    e: React.MouseEvent<HTMLDivElement>,
    menuNode: EventDataNode
  ) {
    // const { path } = menuNode;
  }

  return (
    <MenuContext.Provider
      value={{
        expandedKeys,
        onNodeExpand,
        hoveredMenuKey,
        setHoveredMenuKey,
        onLeafClick,
      }}
    >
      <List
        component="ul"
        classes={classes}
        className={className}
        style={style}
        onMouseOut={() => {
          setHoveredMenuKey(null);
        }}
      >
        {flatternData.map((item, index) => (
          <MenuItem key={index} node={item} />
        ))}
      </List>
    </MenuContext.Provider>
  );
};

export default SideMenu;
