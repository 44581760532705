import React, { useMemo } from "react";
import { Moment } from "moment";
import { Grid, IconButton, Typography, makeStyles } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIcon from "@material-ui/icons/ArrowForwardIos";

const useStyles = makeStyles((theme) => ({
  title: {
    padding: theme.spacing(1, 2),
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  date: {
    padding: theme.spacing(1, 1, 0),
  },
  header: {
    padding: theme.spacing(1.5, 2, 0),
  },
  hidden: {
    visibility: "hidden",
  },
  arrow: {
    padding: 4,
  },
}));

interface HeaderProps {
  month: Moment;
  startDate: Moment | null;
  endDate: Moment | null;
  onChange: (m: Moment) => void;
}

const CalendarHeader: React.FC<HeaderProps> = ({
  month,
  startDate,
  endDate,
  onChange,
}) => {
  const classes = useStyles();
  const previousMonth = useMemo(() => {
    return month.clone().subtract(1, "months");
  }, [month]);
  const nextMonth = useMemo(() => {
    return month.clone();
  }, [month]);

  return (
    <Grid>
      <Grid container className={classes.title}>
        <Grid item xs className={classes.date}>
          <Typography variant="body1">Start Date</Typography>
          <Typography variant="h5" color="inherit">
            {startDate ? startDate.format("M/D/YYYY") : "--"}
          </Typography>
        </Grid>
        <Grid item xs className={classes.date}>
          <Typography variant="body1">End Date</Typography>
          <Typography variant="h5" color="inherit">
            {endDate ? endDate.format("M/D/YYYY") : "--"}
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.header}>
        <Grid container item xs>
          <Grid item>
            <IconButton
              size="small"
              onClick={() =>
                onChange(previousMonth.clone().subtract(1, "months"))
              }
            >
              <ArrowBackIcon className={classes.arrow} />
            </IconButton>
          </Grid>
          <Grid container item xs justify="center" alignItems="center">
            <Typography>{previousMonth.format("MMMM YYYY")}</Typography>
          </Grid>
          <Grid item className={classes.hidden}>
            <IconButton size="small">
              <ArrowForwardIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container item xs>
          <Grid item className={classes.hidden}>
            <IconButton size="small">
              <ArrowBackIcon fontSize="small" />
            </IconButton>
          </Grid>
          <Grid container item xs justify="center" alignItems="center">
            <Typography>{nextMonth.format("MMMM YYYY")}</Typography>
          </Grid>
          <Grid item>
            <IconButton
              size="small"
              onClick={() => onChange(month.clone().add(1, "months"))}
            >
              <ArrowForwardIcon className={classes.arrow} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CalendarHeader;
