import { prepareUrl } from "./utils";

class WebRtcPlayer {
  pc: RTCPeerConnection;
  stream: MediaStream;

  constructor() {
    this.pc = new RTCPeerConnection();

    this.stream = new MediaStream();

    this.pc.ontrack = (event) => {
      if (this.ontrack) {
        this.ontrack(event);
      }
    };
  }

  async play(url: string) {
    this.pc.addTransceiver("audio", { direction: "recvonly" });
    this.pc.addTransceiver("video", { direction: "recvonly" });

    const conf = prepareUrl(url);

    const offer = await this.pc.createOffer();
    await this.pc.setLocalDescription(offer);
    const session = await fetch(conf.apiUrl, {
      method: "POST",
      body: JSON.stringify({
        api: conf.apiUrl,
        tid: conf.tid,
        streamurl: conf.streamUrl,
        clientip: null,
        sdp: offer.sdp,
      }),
    })
      .then((res) => res.json())
      .then((res) => res)
      .catch((e) => e);
    await this.pc.setRemoteDescription(
      new RTCSessionDescription({ type: "answer", sdp: session.sdp })
    );
    return session;
  }

  close() {
    this.pc && this.pc.close();
  }

  ontrack(event: any) {
    this.stream.addTrack(event.track);
  }
}

export default WebRtcPlayer;
