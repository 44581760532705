
    import React from 'react';
    import { dynamic } from 'dumi';
    import { Link, AnchorLink } from 'dumi/theme';
    import API from 'dumi-theme-default/es/builtins/API.js';
import Alert from 'dumi-theme-default/es/builtins/Alert.js';
import Badge from 'dumi-theme-default/es/builtins/Badge.js';
import Example from 'dumi-theme-default/es/builtins/Example.js';
import Previewer from 'dumi-theme-default/es/builtins/Previewer.js';
import SourceCode from 'dumi-theme-default/es/builtins/SourceCode.js';
    import DUMI_ALL_DEMOS from '@@/dumi/demos';

    const DumiDemo1 = React.memo(DUMI_ALL_DEMOS['auto-complete-demo'].component);

    export default (props) => {
      // scroll to anchor after page component loaded
      React.useEffect(() => {
        if (props?.location?.hash) {
          AnchorLink.scrollToAnchor(decodeURIComponent(props.location.hash.slice(1)));
        }
      }, []);

      return (
        <>
          
          <React.Fragment><div className="markdown"><h2 id="autocomplete"><AnchorLink to="#autocomplete" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>Autocomplete</h2>
<p>Autocomplete</p>
<h3 id="何时使用"><AnchorLink to="#何时使用" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>何时使用</h3>
<ul>
<li>需要展示 Autocomplete</li>
</ul>
<h3 id="代码演示"><AnchorLink to="#代码演示" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>代码演示</h3>
<p>简单使用：</p>
</div><Previewer {...DUMI_ALL_DEMOS['auto-complete-demo'].previewerProps}><DumiDemo1 /></Previewer>
<div className="markdown"><h3 id="api"><AnchorLink to="#api" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>API</h3>
<h5 id="autocomplete-1"><AnchorLink to="#autocomplete-1" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>Autocomplete</h5>











































































































<table><thead><tr><th>参数</th><th>说明</th><th>类型</th><th>默认值</th></tr></thead><tbody><tr><td>value</td><td>选中的值</td><td>string | number</td><td>-</td></tr><tr><td>className</td><td /><td>string</td><td>-</td></tr><tr><td>placeholder</td><td>placeholder</td><td>string</td><td>-</td></tr><tr><td>disableClearable</td><td>显示清空按钮</td><td>boolean</td><td>-</td></tr><tr><td>freeSolo</td><td /><td>boolean</td><td>-</td></tr><tr><td>disableCircle</td><td /><td>boolean</td><td>-</td></tr><tr><td>size</td><td /><td>small | medium</td><td>-</td></tr><tr><td>startAdornment</td><td /><td>React.ReactNode</td><td>-</td></tr><tr><td>fullWidth</td><td /><td>boolean</td><td>-</td></tr><tr><td>popupIcon</td><td /><td>React.ReactNode</td><td>-</td></tr><tr><td>classes</td><td /><td>Object</td><td>-</td></tr><tr><td>style</td><td /><td>React.CSSProperties</td><td>-</td></tr><tr><td>options</td><td>切换的选项</td><td>Array&lt;{"{"} label: string; value: string | number; [key: string]: any {"}"}&gt;</td><td>-</td></tr><tr><td>onChange</td><td>选中值变化时的回调</td><td>(value: string) =&gt; void</td><td>-</td></tr><tr><td>request</td><td /><td>(keyword: string) =&gt; Promise</td><td>-</td></tr><tr><td>renderOption</td><td /><td>(option: OptionType) =&gt; React.ReactNode</td><td>-</td></tr></tbody></table></div></React.Fragment>
        </>
      );
  }