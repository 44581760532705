
    import React from 'react';
    import { dynamic } from 'dumi';
    import { Link, AnchorLink } from 'dumi/theme';
    import API from 'dumi-theme-default/es/builtins/API.js';
import Alert from 'dumi-theme-default/es/builtins/Alert.js';
import Badge from 'dumi-theme-default/es/builtins/Badge.js';
import Example from 'dumi-theme-default/es/builtins/Example.js';
import Previewer from 'dumi-theme-default/es/builtins/Previewer.js';
import SourceCode from 'dumi-theme-default/es/builtins/SourceCode.js';
    import DUMI_ALL_DEMOS from '@@/dumi/demos';

    const DumiDemo1 = React.memo(DUMI_ALL_DEMOS['webrtc-player-demo'].component);

    export default (props) => {
      // scroll to anchor after page component loaded
      React.useEffect(() => {
        if (props?.location?.hash) {
          AnchorLink.scrollToAnchor(decodeURIComponent(props.location.hash.slice(1)));
        }
      }, []);

      return (
        <>
          
          <React.Fragment><div className="markdown"><h2 id="webrtcplayer"><AnchorLink to="#webrtcplayer" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>WebrtcPlayer</h2>
<p>Webrtc 播放器</p>
<h3 id="何时使用"><AnchorLink to="#何时使用" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>何时使用</h3>
<ul>
<li>需要展示 Webrtc 播放器</li>
</ul>
<h3 id="代码演示"><AnchorLink to="#代码演示" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>代码演示</h3>
<p>简单使用：</p>
</div><Previewer {...DUMI_ALL_DEMOS['webrtc-player-demo'].previewerProps}><DumiDemo1 /></Previewer>
<div className="markdown"><h3 id="api"><AnchorLink to="#api" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>API</h3>
<h5 id="webrtcplayer-1"><AnchorLink to="#webrtcplayer-1" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>WebrtcPlayer</h5>





















































<table><thead><tr><th>参数</th><th>说明</th><th>类型</th><th>默认值</th></tr></thead><tbody><tr><td>url</td><td>视频 url</td><td>string</td><td>-</td></tr><tr><td>autoplay</td><td>是否自动播放</td><td>boolean</td><td>false</td></tr><tr><td>paused</td><td>是否暂停</td><td>boolean</td><td>false</td></tr><tr><td>onCanPlay</td><td>可以播放时的回调</td><td>() =&gt; void</td><td>-</td></tr><tr><td>onError</td><td>错误的回调</td><td>(err: string, errData: any, hls.current: HLSPlayerType, HlsPlayer: Hls) =&gt; void</td><td>-</td></tr><tr><td>onTimeUpdate</td><td>播放时间发生更新的回调</td><td>(currentTime: number)) =&gt; void</td><td>-</td></tr><tr><td>setLoading</td><td>外部设置加载状态的回调</td><td>(loading: boolean)) =&gt; void</td><td>-</td></tr></tbody></table></div></React.Fragment>
        </>
      );
  }