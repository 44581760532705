import moment, { Moment } from "moment";

export function getDaysInMonth(date: Moment) {
  const day = date.clone();
  const startDay = day.clone().startOf("month");
  const endDay = day.clone().endOf("month");
  const days = Array(day.daysInMonth())
    .fill(null)
    .map((_, idx) => startDay.clone().add(idx, "days"));
  const result = Array(startDay.clone().days())
    .fill(null)
    .concat(days)
    .concat(Array(6 - endDay.clone().days()).fill(null));
  return result.length >= 42 ? result : result.concat(Array(7).fill(null));
}

export function chunks<T>(array: ReadonlyArray<T>, size: number) {
  return Array.from({ length: Math.ceil(array.length / size) }, (_, i) =>
    array.slice(i * size, i * size + size)
  );
}

export function getStartAndEndTime(
  timeType: string
): [Moment | null, Moment | null] | null[] {
  let startTime = null;
  let endTime = null;
  switch (timeType) {
    case "oneHour":
      startTime = moment().subtract(1, "hour");
      endTime = moment();
      break;
    case "today":
      startTime = moment().startOf("day");
      endTime = moment();
      break;
    case "oneWeek":
      startTime = moment().subtract(1, "week").startOf("day");
      endTime = moment();
      break;
    case "oneMonth":
      startTime = moment().subtract(1, "month").startOf("day");
      endTime = moment();
      break;
    case "oneYear":
      startTime = moment().subtract(1, "year").startOf("day");
      endTime = moment();
      break;
    default:
      break;
  }
  return [startTime, endTime];
}
