import React from "react";
import { Button, Typography, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid #eee`,
    background: "#eee",
    borderRadius: theme.spacing(2),
    padding: "4px 10px 4px 13px",
    height: 32,
    textTransform: "none",
    "&:hover": {
      background: "#ddd",
    },
  },
  hover: {
    "& $arrow": {
      transform: "rotate(180deg)",
      transition: "all .3s",
    },
  },
  selected: {
    border: `1px solid ${theme.palette.primary.main}`,
    background: theme.palette.primary.light,
    color: theme.palette.primary.main,
    "&:hover": {
      background: theme.palette.primary.light,
    },
    "& svg": {
      color: theme.palette.primary.main,
    },
    "& .MuiButton-startIcon": {
      marginRight: theme.spacing(0.5),
    },
  },
  arrow: {
    cursor: "pointer",
    padding: 1,
  },
  clear: {
    cursor: "pointer",
  },
  label: {
    whiteSpace: "nowrap",
    marginRight: 4,
  },
}));

interface DropdownProps {
  label?: string;
  preLabel?: string;
  value?: string;
  startIcon?: React.ReactNode;
  open: boolean;
  onClick: () => void;
  onClear: () => void;
}

const Dropdown: React.FC<DropdownProps> = ({
  label,
  preLabel,
  value,
  startIcon,
  open,
  onClick,
  onClear,
}) => {
  const classes = useStyles();

  return (
    <Button
      onClick={onClick}
      className={clsx(
        classes.root,
        value && classes.selected,
        open && classes.hover
      )}
      startIcon={startIcon}
    >
      <Typography variant="body2" className={classes.label}>
        {preLabel ? `${preLabel}: ` : ""}
        {value ? value : label ? label : "All"}
      </Typography>
      {value && (
        <ClearIcon
          onClick={(e) => {
            e.stopPropagation();
            onClear();
          }}
          fontSize="small"
          color="action"
          className={classes.clear}
        />
      )}
      <ArrowDropDownIcon color="action" className={classes.arrow} />
    </Button>
  );
};

export default Dropdown;
