import React, { useState, useEffect, useMemo } from "react";
import moment, { Moment } from "moment";
import { Grid, Switch, FormControlLabel, makeStyles } from "@material-ui/core";
import TimeList from "./TimeList";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 2),
  },
  input: {
    "&>div": {
      paddingRight: 0,
    },
  },
}));

interface TimeRangeProps {
  timeRange: Array<Moment | null>;
  isOneDay?: boolean;
  onChange: (prop: string) => (value: Moment | null) => void;
  onClear: () => void;
}

function getTime(hour: number, minutes: number) {
  return `${String(hour >= 12 ? hour - 12 : hour).padStart(2, "0")}:${String(
    minutes
  ).padStart(2, "0")} ${hour >= 12 ? "PM" : "AM"}`;
}

const options = Array(48)
  .fill(null)
  .map((_, index) => {
    const hour = Math.floor(index / 2);
    const minutes = index % 2 === 0 ? 0 : 30;
    const now = moment();
    now.hours(hour);
    now.minutes(minutes);
    return { value: now, label: getTime(hour, minutes), disabled: false };
  });

const TimeRange: React.FC<TimeRangeProps> = ({
  timeRange,
  isOneDay = false,
  onChange,
  onClear,
}) => {
  const classes = useStyles();

  const [checked, setChecked] = useState(
    !(
      timeRange.every((item) => item === null) ||
      (timeRange[0] !== null &&
        timeRange[0].format("HH:mm") === "00:00" &&
        timeRange[1] !== null &&
        timeRange[1].format("HH:mm") === "23:59")
    )
  );

  function getNumber(v) {
    return v.hours() * 60 + v.minutes();
  }

  const startOptions = useMemo(() => {
    if (!isOneDay || !timeRange[1]) {
      return options;
    } else {
      return options.map((item) => {
        return getNumber(item.value) > getNumber(timeRange[1])
          ? { ...item, disabled: true }
          : item;
      });
    }
  }, [isOneDay, timeRange[1]]);

  const endOptions = useMemo(() => {
    if (!isOneDay || !timeRange[0]) {
      return options;
    }
    return options.map((item) => {
      return getNumber(item.value) < getNumber(timeRange[0])
        ? { ...item, disabled: true }
        : item;
    });
  }, [isOneDay, timeRange[0]]);

  return (
    <Grid className={classes.root}>
      <Grid style={{ marginBottom: 8 }}>
        <FormControlLabel
          control={
            <Switch
              checked={checked}
              onChange={(e) => {
                setChecked(e.target.checked);
                if (!e.target.checked) {
                  onClear();
                }
              }}
              color="primary"
              name="checkedA"
            />
          }
          label="Time"
        />
      </Grid>
      {checked && (
        <Grid container>
          <Grid item xs>
            <TimeList
              label="Start Time"
              options={startOptions}
              value={timeRange[0]}
              onChange={onChange("startDateTime")}
            />
          </Grid>
          <Grid item xs>
            <TimeList
              label="End Time"
              options={endOptions}
              value={timeRange[1]}
              onChange={onChange("endDateTime")}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default TimeRange;
