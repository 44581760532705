import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import noResult from "../assets/no_results_robot.png";

const useStyles = makeStyles({
  root: {
    lineHeight: "50px",
  },
});

export type EmptyProps = {
  image?: string;
  description?: React.ReactNode;
  imageStyle?: React.CSSProperties;
};

const Empty: React.FC<EmptyProps> = ({
  image,
  description,
  imageStyle,
}: EmptyProps) => {
  const classes = useStyles();
  return (
    <Grid
      container
      justify="center"
      direction="column"
      alignItems="center"
      className={classes.root}
    >
      <Grid>
        <img src={image || noResult} alt="No Data" style={imageStyle} />
      </Grid>
      {description ? (
        <Grid>{description}</Grid>
      ) : (
        <>
          <Grid>
            <Typography variant="h6" color="primary">
              Turing Robot has found no records.
            </Typography>
          </Grid>
          <Grid>
            <Typography>It encourages you to try again.</Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Empty;
