import React from "react";
import {
  makeStyles,
  Tooltip as MuiTooltip,
  TooltipProps,
} from "@material-ui/core";

export type { TooltipProps };

const useStyles = makeStyles(() => ({
  span: {
    display: "inline-block",
    verticalAlign: "top",
  },
}));

const Tooltip: React.FC<TooltipProps> = (props) => {
  const classes = useStyles();

  return (
    <MuiTooltip {...props}>
      <span className={classes.span}>{props.children}</span>
    </MuiTooltip>
  );
};

export default Tooltip;
