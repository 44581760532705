import { Key, NodeType, FlatternNode } from "./interface";

export function arrDel(list: Key[], value: Key) {
  const clone = list.slice();
  const index = clone.indexOf(value);
  if (index >= 0) {
    clone.splice(index, 1);
  }
  return clone;
}

export function arrAdd(list: Key[], value: Key) {
  const clone = list.slice();
  if (clone.indexOf(value) === -1) {
    clone.push(value);
  }
  return clone;
}

export function flatternArray(data: NodeType[], depth = 0): FlatternNode[] {
  return data
    .filter((item) => !item.hideInMenu)
    .map((item) => ({
      ...item,
      depth,
      key: (item.name || "") + item.path + depth,
      children: flatternArray(item.children || [], depth + 1),
    }));
}
