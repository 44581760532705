import React, { useEffect, useRef } from "react";
import { List, ListItem } from "@material-ui/core";
import { OptionType } from "./index";

interface ProListProps {
  selected?: string | number;
  options: OptionType[];
  renderOption: any;
  onSelect: (v: OptionType) => void;
}

const ProList: React.FC<ProListProps> = ({
  options,
  selected,
  onSelect,
  renderOption,
}) => {
  const listRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (listRef && listRef.current && options.length && selected) {
      const option = options.find(
        (item: OptionType) => item.value === selected
      );
      if (option) {
        const idx = options.indexOf(option);
        const element: HTMLDivElement | null = listRef.current.querySelector(
          `#option-index-${idx}`
        );
        if (element) {
          listRef.current.scrollTop = element.offsetTop - 40;
        }
      }
    }
  }, []);

  return (
    <List ref={listRef} style={{ maxHeight: 300, overflow: "auto" }}>
      {options.map((item, idx) => (
        <ListItem
          selected={selected === item.value}
          key={idx}
          id={`option-index-${idx}`}
          onMouseDown={() => {
            onSelect(item);
          }}
          button
        >
          {renderOption ? renderOption(item) : item.label}
        </ListItem>
      ))}
    </List>
  );
};

export default ProList;
