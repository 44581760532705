import React, { useRef, useMemo, useState } from "react";

import { TextField, makeStyles } from "@material-ui/core";
import MaterailAutocomplete from "@material-ui/lab/Autocomplete";
import { AutocompleteProps, OptionType } from "./interface";
import { useControlled } from "../utils";

export type { AutocompleteProps, OptionType };

const useStyles = makeStyles(() => ({
  textField: {
    cursor: "pointer",
    "&>div": {
      paddingTop: "2px !important",
      paddingBottom: "2px !important",
    },
    "& fieldset": {
      borderRadius: 16,
      borderWidth: "1px !important",
    },
  },
}));

const Autocomplete: React.FC<AutocompleteProps> = (props) => {
  const {
    options = [],
    value: inputValue,
    size,
    disableCircle,
    placeholder = "",
    label = "",
    fullWidth = true,
    freeSolo = false,
    disableClearable,
    startAdornment,
    popupIcon,
    className,
    classes,
    style,
    renderOption,
    request,
    onChange,
  } = props;
  const timer = useRef(0);
  const styles = useStyles();
  const [open, setOpen] = useState(false);

  const [value, setValue] = useControlled({
    controlled: inputValue,
    default: "",
  });

  function handleChange(event: React.ChangeEvent<{}>, option: any) {
    setValue(option);
    onChange &&
      onChange(
        typeof option === "object" && option !== null ? option.value : option
      );
  }

  const optionValue = useMemo(() => {
    return options.find((item) => item.value === value) || null;
  }, [value, options]);

  function handleInputChange(event: React.ChangeEvent<{}>, inputValue: string) {
    if (request) {
      if (timer.current) {
        clearTimeout(timer.current);
      }

      // if backspace input and value existed, clear it
      if (
        optionValue &&
        optionValue.label &&
        typeof inputValue === "string" &&
        optionValue.label.length - inputValue.length === 1
      ) {
        handleChange(null as any, null);
      } else {
        timer.current = window.setTimeout(() => {
          request(inputValue);
        }, 300);
      }
    }
  }

  return (
    <MaterailAutocomplete
      fullWidth={fullWidth}
      size={size}
      clearOnBlur
      options={options}
      style={style}
      open={open}
      onOpen={(e: any) => {
        if (e.type !== "mousedown" || e.target.value !== "") {
          setOpen(true);
        }
      }}
      onClose={() => {
        setOpen(false);
      }}
      classes={classes}
      className={className}
      disableClearable={disableClearable}
      freeSolo={freeSolo}
      value={optionValue}
      popupIcon={popupIcon}
      onInputChange={handleInputChange}
      onChange={handleChange}
      renderOption={renderOption}
      getOptionLabel={(option) =>
        option.label === undefined ? (option as any) : option.label
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          style={{ background: "white", fontSize: 14 }}
          className={disableCircle ? "" : styles.textField}
          InputProps={{
            ...params.InputProps,
            startAdornment: startAdornment,
          }}
          variant="outlined"
        />
      )}
    />
  );
};

export default Autocomplete;
