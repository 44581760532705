import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { Moment } from "moment";
import { getDaysInMonth, chunks } from "./utils";
import Day from "./Day";

const WEEK_DAYS = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 1, 0),
  },
  weekday: {
    width: 36,
    display: "inline-block",
    textAlign: "center",
  },
}));

interface MonthProps {
  month: Moment;
  rangeValid?: [Moment | null, Moment | null];
  startDate: Moment | null;
  endDate: Moment | null;
  handleClickDay: (d: Moment) => void;
}

const Month: React.FC<MonthProps> = ({
  month,
  rangeValid,
  startDate,
  endDate,
  handleClickDay,
}) => {
  const classes = useStyles();

  return (
    <Grid className={classes.root}>
      <Grid>
        {WEEK_DAYS.map((day) => (
          <Typography
            className={classes.weekday}
            color="textSecondary"
            key={day}
            variant="caption"
          >
            {day}
          </Typography>
        ))}
      </Grid>
      {chunks(getDaysInMonth(month), 7).map((week, idx) => (
        <Grid container key={idx}>
          {week.map((day, i) => (
            <Day
              key={i}
              day={day}
              rangeValid={rangeValid}
              startDate={startDate}
              endDate={endDate}
              onClick={handleClickDay}
            />
          ))}
        </Grid>
      ))}
    </Grid>
  );
};

export default Month;
