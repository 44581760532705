import moment, { Moment } from "moment";

export function getStartAndEndTime(
  timeType: string,
  startDateTime: Moment | null,
  endDateTime: Moment | null
) {
  let startTime = startDateTime;
  let endTime = endDateTime;
  switch (timeType) {
    case "oneHour":
      startTime = moment().subtract(1, "hour");
      endTime = moment();
      break;
    case "today":
      startTime = moment().startOf("day");
      endTime = moment();
      break;
    case "oneWeek":
      startTime = moment().subtract(1, "week").startOf("day");
      endTime = moment();
      break;
    case "oneMonth":
      startTime = moment().subtract(1, "month").startOf("day");
      endTime = moment();
      break;
    case "oneYear":
      startTime = moment().subtract(1, "year").startOf("day");
      endTime = moment();
      break;
    case "customized":
      startTime = startTime ? startTime : moment().startOf("day");
      endTime = endTime ? endTime : moment();
      break;
    case "all":
      startTime = null;
      endTime = null;
      break;
    default:
      break;
  }
  return { startTime, endTime };
}
