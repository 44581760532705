import React, { useMemo } from "react";

import { ColumnType } from "./interface";

type CellType = {
  node: ColumnType<any>;
  record: any;
  index: number;
};

const Cell: React.FC<CellType> = ({ node, record, index }) => {
  const childNode = useMemo(() => {
    let childNode;
    const value = node.dataIndex ? record[node.dataIndex] : record;
    childNode = value;
    if (node.render) {
      childNode = node.render(value, record, index);
    }
    return childNode;
  }, [node, record, index]);

  return <div>{childNode}</div>;
};

export default Cell;
