import React, { PureComponent } from "react";
import { render } from "react-dom";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  createStyles,
  Theme,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import globalTheme from "../theme";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      minWidth: "300px",
      paddingTop: 16,
      paddingBottom: 16,
    },
    title: {
      fontSize: 20,
      lineHeight: "1.6em",
      color: "rgba(0,0,0,0.87)",
      marginBottom: 10,
    },
    description: {
      fontSize: 16,
      lineHeight: "1.5em",
      color: "rgba(0,0,0,0.54)",
    },
    noBorder: {
      border: "none !important",
    },
    cancelButton: {
      color: "rgba(0,0,0,0.54)",
      textTransform: "capitalize",
    },
    okButton: {
      textTransform: "capitalize",
    },
  })
);

const Confirm = ({
  open,
  title,
  content,
  okText,
  showCancel,
  cancelText,
  onOk,
  onCancel,
}: ConfirmData): any => {
  const classes = useStyles();
  return (
    <Dialog disableBackdropClick disableEscapeKeyDown open={open}>
      <DialogContent className={classes.content}>
        {title && (
          <Typography component="h2" className={classes.title}>
            {title}
          </Typography>
        )}
        <Typography className={classes.description}>{content}</Typography>
      </DialogContent>
      <DialogActions className={classes.noBorder}>
        {showCancel && (
          <Button autoFocus onClick={onCancel} className={classes.cancelButton}>
            {cancelText ? cancelText : "Cancel"}
          </Button>
        )}

        <Button onClick={onOk} color="primary" className={classes.okButton}>
          {okText ? okText : "Ok"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface ConfirmData {
  id?: string;
  open: boolean;
  title?: string | React.ReactNode;
  content: string | React.ReactNode;
  okText?: string;
  onOk?: () => void;
  showCancel?: boolean;
  cancelText?: string;
  onCancel?: () => void;
}

interface State {
  data: Array<ConfirmData>;
}

class ConfirmContainer extends PureComponent<any, State> {
  container?: HTMLDivElement;
  constructor(props: any) {
    super(props);
    this.state = {
      data: [],
    };
  }

  _removeConfirm(id: string) {
    this.setState((state) => {
      return {
        data: state.data.filter((v) => v.id !== id),
      };
    });
  }

  _addConfirm(confirmData: ConfirmData) {
    this.setState((state) => {
      state.data.push(confirmData);
      return {
        data: [...state.data],
      };
    });
  }

  _handleOk = (confirmData: ConfirmData) => {
    confirmData.onOk && confirmData.onOk();
    this._removeConfirm(confirmData.id);
  };
  _handleCancel = (confirmData: ConfirmData) => {
    confirmData.onCancel && confirmData.onCancel();
    this._removeConfirm(confirmData.id);
  };

  confirm = (data: Omit<ConfirmData, "id" | "open">) => {
    const id = `${Math.random()}`;
    const confirmData = {
      id,
      open: true,
      showCancel: true,
      ...data,
    };
    this._addConfirm(confirmData);

    const close = this._removeConfirm.bind(this, id);

    return close;
  };

  alert = (
    data: Omit<ConfirmData, "id" | "open" | "showCancel" | "cancelText">
  ) => {
    const id = `${Math.random()}`;
    const confirmData = {
      id,
      open: true,
      ...data,
      showCancel: false,
    };
    this._addConfirm(confirmData);

    const close = this._removeConfirm.bind(this, id);

    return close;
  };

  render() {
    const { data } = this.state;
    return (
      <ThemeProvider theme={globalTheme}>
        <div>
          {data.map((v) => (
            <Confirm
              key={v.id}
              open={v.open}
              title={v.title}
              content={v.content}
              okText={v.okText}
              showCancel={v.showCancel}
              cancelText={v.cancelText}
              onOk={() => {
                this._handleOk(v);
              }}
              onCancel={() => {
                this._handleCancel(v);
              }}
            />
          ))}
        </div>
      </ThemeProvider>
    );
  }
}

let confirmInstance: any;

function init() {
  if (confirmInstance) {
    return confirmInstance;
  }
  const component = <ConfirmContainer />;

  const container = document.createElement("div");
  document.body.appendChild(container);
  const newConfirmInstance = render(component, container);
  confirmInstance = newConfirmInstance;
  return confirmInstance;
}

export default {
  confirm: function (confirmData: Omit<ConfirmData, "id" | "open">) {
    const confirm = init();
    return confirm.confirm(confirmData);
  },
  alert: function (
    confirmData: Omit<ConfirmData, "id" | "open" | "showCancel" | "cancelText">
  ) {
    const confirm = init();
    return confirm.alert(confirmData);
  },
};
