import React, { useMemo } from "react";

import {
  Paper,
  Table as MaterialTable,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Typography,
} from "@material-ui/core";

import useFetchData from "./useFetchData";
import Cell from "./Cell";
import { ProTableProps, ParamsType, RequestData } from "./interface";

export type { ProTableProps };

const ProTable = <T extends Record<string, any>, U extends ParamsType>(
  props: ProTableProps<T, U>
) => {
  const {
    columns = [],
    defaultData,
    request,
    pagination: propsPagination,
  } = props;

  const fetchData = useMemo(() => {
    if (!request) return undefined;

    return async (pageParams?: Record<string, any>) => {
      const response = await request(pageParams as U);
      return response as RequestData<T>;
    };
  }, [request]);

  const action = useFetchData(fetchData, defaultData, {
    pageInfo: propsPagination ? propsPagination : { pageSize: 10 },
  });

  const pagination = useMemo(() => {
    return {
      total: action.pageInfo.total,
      current: action.pageInfo.current,
      pageSize: action.pageInfo.pageSize,
    };
  }, [action]);

  return (
    <Paper>
      <MaterialTable>
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell key={index}>{column.title}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        {action.dataSource.length > 0 && (
          <TableBody>
            {action.dataSource.map((record: T, index: number) => (
              <TableRow key={index}>
                {columns.map((column, index) => (
                  <TableCell key={index}>
                    <Cell node={column} record={record} index={index} />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        )}
      </MaterialTable>
      {action.dataSource.length === 0 && (
        <Typography
          variant="body2"
          color="textSecondary"
          style={{ marginTop: 30 }}
        >
          暂无数据
        </Typography>
      )}
      {propsPagination && (
        <TablePagination
          component="div"
          count={pagination.total}
          page={pagination.current}
          rowsPerPage={pagination.pageSize}
          rowsPerPageOptions={[pagination.pageSize]}
          onChangePage={(_, newPage) => {
            action.setPageInfo({
              current: newPage,
            });
          }}
          onChangeRowsPerPage={(event) => {
            action.setPageInfo({
              pageSize: parseInt(event.target.value),
            });
          }}
        />
      )}
    </Paper>
  );
};

export default ProTable;
