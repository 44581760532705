const defaultPath = "/rtc/v1/play/";

export function prepareUrl(webrtcUrl: string) {
  const urlObject = parse(webrtcUrl);

  // If user specifies the schema, use it as API schema.
  let schema = urlObject.user_query.schema;
  schema = schema ? schema + ":" : window.location.protocol;

  let port = urlObject.port || 1985;
  if (schema === "https:") {
    port = urlObject.port || 443;
  }

  // @see https://github.com/rtcdn/rtcdn-draft
  let api = urlObject.user_query.play || defaultPath;
  if (api.lastIndexOf("/") !== api.length - 1) {
    api += "/";
  }

  let apiUrl = schema + "//" + urlObject.server + ":" + port + api;
  for (const key in urlObject.user_query) {
    if (key !== "api" && key !== "play") {
      apiUrl += "&" + key + "=" + urlObject.user_query[key];
    }
  }
  // Replace /rtc/v1/play/&k=v to /rtc/v1/play/?k=v
  apiUrl = apiUrl.replace(api + "&", api + "?");

  const streamUrl = urlObject.url;

  return {
    apiUrl: apiUrl,
    streamUrl: streamUrl,
    schema: schema,
    urlObject: urlObject,
    port: port,
    tid: Math.floor(new Date().getTime() * Math.random() * 100)
      .toString(16)
      .substr(0, 7),
  };
}

function fill(query_string: string, obj: any) {
  // pure user query object.
  obj.user_query = {} as any;

  if (query_string.length === 0) {
    return;
  }

  // split again for angularjs.
  if (query_string.indexOf("?") >= 0) {
    query_string = query_string.split("?")[1];
  }

  const queries = query_string.split("&");
  for (let i = 0; i < queries.length; i++) {
    const elem = queries[i];

    const query = elem.split("=");
    obj[query[0]] = query[1];
    obj.user_query[query[0]] = query[1];
  }

  // alias domain for vhost.
  if (obj.domain) {
    obj.vhost = obj.domain;
  }
}

function parse(url: string) {
  // @see: http://stackoverflow.com/questions/10469575/how-to-use-location-object-to-parse-url-without-redirecting-the-page-in-javascri
  const a = document.createElement("a");
  a.href = url
    .replace("rtmp://", "http://")
    .replace("webrtc://", "http://")
    .replace("rtc://", "http://");

  let vhost = a.hostname;
  let app = a.pathname.substr(1, a.pathname.lastIndexOf("/") - 1);
  const stream = a.pathname.substr(a.pathname.lastIndexOf("/") + 1);

  // parse the vhost in the params of app, that srs supports.
  app = app.replace("...vhost...", "?vhost=");
  if (app.indexOf("?") >= 0) {
    const params = app.substr(app.indexOf("?"));
    app = app.substr(0, app.indexOf("?"));

    if (params.indexOf("vhost=") > 0) {
      vhost = params.substr(params.indexOf("vhost=") + "vhost=".length);
      if (vhost.indexOf("&") > 0) {
        vhost = vhost.substr(0, vhost.indexOf("&"));
      }
    }
  }

  // when vhost equals to server, and server is ip,
  // the vhost is __defaultVhost__
  if (a.hostname === vhost) {
    const re = /^(\d+)\.(\d+)\.(\d+)\.(\d+)$/;
    if (re.test(a.hostname)) {
      vhost = "__defaultVhost__";
    }
  }

  // parse the schema
  let schema = "rtmp";
  if (url.indexOf("://") > 0) {
    schema = url.substr(0, url.indexOf("://"));
  }

  let port: string | number = a.port;
  if (!port) {
    if (schema === "http") {
      port = 80;
    } else if (schema === "https") {
      port = 443;
    } else if (schema === "rtmp") {
      port = 1935;
    }
  }

  const ret: any = {
    url: url,
    schema: schema,
    server: a.hostname,
    port: port,
    vhost: vhost,
    app: app,
    stream: stream,
  };
  fill(a.search, ret);

  // For webrtc API, we use 443 if page is https, or schema specified it.
  if (!ret.port) {
    if (schema === "webrtc" || schema === "rtc") {
      if (ret.user_query.schema === "https") {
        ret.port = 443;
      } else if (window.location.href.indexOf("https://") === 0) {
        ret.port = 443;
      } else {
        // For WebRTC, SRS use 1985 as default API port.
        ret.port = 1985;
      }
    }
  }

  return ret;
}
