import React, { useState, useEffect, useRef, useMemo } from "react";
import moment, { Moment } from "moment";
import { Popover } from "@material-ui/core";

import Calendar from "./Calendar";
import Dropdown from "../dropdown";
import List from "../pro-select/List";
import { useControlled } from "../utils";
import { getStartAndEndTime } from "./utils";

export interface DateRangeProps {
  label?: string;
  preLabel?: string;
  timeDisable?: boolean;
  rangeValid?: [Moment | null, Moment | null];
  hasNoYear?: boolean;
  value?: { type: string; range: [Moment | null, Moment | null] | null[] };
  onChange?: (v: {
    type: string;
    range: [Moment | null, Moment | null] | null[];
  }) => void;
}

const DateRange: React.FC<DateRangeProps> = ({
  label,
  preLabel,
  timeDisable,
  rangeValid,
  hasNoYear,
  value: defaultValue,
  onChange,
}) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [listOpen, setListOpen] = useState(false);
  const [value, setValue] = useControlled({
    controlled: defaultValue,
    default: { type: "customized", range: [null, null], hasTime: false },
  });

  const [, refresh] = useState(1);

  const options = hasNoYear
    ? [
        { value: "oneHour", label: "Last Hour" },
        { value: "today", label: "Last Day" },
        { value: "oneWeek", label: "Last Week" },
        { value: "oneMonth", label: "Last Month" },
        { value: "customized", label: "Custom" },
      ]
    : [
        { value: "oneHour", label: "Last Hour" },
        { value: "today", label: "Last Day" },
        { value: "oneWeek", label: "Last Week" },
        { value: "oneMonth", label: "Last Month" },
        { value: "oneYear", label: "Last Year" },
        { value: "customized", label: "Custom" },
      ];

  const textValue = useMemo(() => {
    if (value && value.type !== "customized") {
      const selected = options.find((item) => item.value === value.type);
      return (selected && selected.label) || "";
    } else if (
      value &&
      value.type === "customized" &&
      value.range &&
      value.range.some((item: Moment | null) => item === null)
    ) {
      return "";
    } else if (
      value &&
      value.type === "customized" &&
      Array.isArray(value.range)
    ) {
      return value.range
        .map(
          (item: Moment | null) =>
            item && item.format(value.hasTime ? "M/D/YYYY hh:mm A" : "M/D/YYYY")
        )
        .join("~");
    }
    return "";
  }, [value]);

  useEffect(() => {
    refresh(0);
  }, []);

  function handleClose() {
    setOpen(false);
  }

  function handleChange(
    range: [Moment | null, Moment | null],
    hasTime?: boolean
  ) {
    const newValue = { type: "customized", range, hasTime };
    onChange && onChange(newValue);
    setOpen(false);
    setValue(newValue);
  }

  function handleListChange(selItem: any) {
    setListOpen(false);
    if (selItem.value === "customized") {
      setOpen(true);
    } else {
      const newValue = {
        type: selItem.value,
        range: getStartAndEndTime(selItem.value),
      };
      setValue(newValue);
      onChange && onChange(newValue);
    }
  }

  return (
    <div>
      <div ref={textRef}>
        <Dropdown
          preLabel={preLabel}
          label={label}
          open={listOpen || open}
          value={textValue}
          onClick={() => setListOpen(true)}
          onClear={() => handleChange([null, null])}
        />
      </div>
      {open && textRef && textRef.current && (
        <Popover
          open={true}
          anchorEl={textRef.current}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          onClose={() => setOpen(false)}
        >
          <Calendar
            value={value.range || [null, null]}
            timeDisable={timeDisable}
            rangeValid={rangeValid}
            onChange={handleChange}
            onClose={handleClose}
            hasTime={value.hasTime}
          />
        </Popover>
      )}
      {listOpen && textRef && textRef.current && (
        <Popover
          open={true}
          anchorEl={textRef.current}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          onClose={() => setListOpen(false)}
        >
          {options.length === 0 ? (
            "No Data"
          ) : (
            <List
              options={options}
              selected={value && value.type}
              onChange={handleListChange}
            />
          )}
        </Popover>
      )}
    </div>
  );
};

export default DateRange;
