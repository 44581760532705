import React, { PureComponent } from "react";
import { render } from "react-dom";
import {
  makeStyles,
  createStyles,
  Theme,
  Snackbar,
  ThemeProvider,
} from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import globalTheme from "../theme";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alert: {
      marginBottom: 10,
      paddingTop: 3,
      paddingBottom: 3,
    },
  })
);

function Alert(props: AlertProps) {
  const classes = useStyles();
  return (
    <MuiAlert
      elevation={6}
      variant="filled"
      className={classes.alert}
      {...props}
    />
  );
}

interface Message {
  content: string | React.ReactNode;
  type: "success" | "error" | "warning" | "info";
  id: string;
  duration?: number;
}

interface State {
  open: boolean;
  msgs: Array<Message>;
}

class MessageComponent extends PureComponent<any, State> {
  container?: HTMLDivElement;
  constructor(props: any) {
    super(props);
    this.state = {
      open: false,
      msgs: [],
    };
  }

  _removeMsg(id: string) {
    const { msgs } = this.state;
    const filtered = msgs.filter((v) => v.id !== id);
    this.setState({
      open: filtered.length > 0,
      msgs: filtered,
    });
  }

  _addMsg(msg: Message) {
    // this._init();
    const { msgs } = this.state;
    this.setState({
      open: true,
      msgs: [...msgs, msg],
    });

    const close = this._removeMsg.bind(this, msg.id);

    const hideTimeout = setTimeout(() => {
      close();
    }, msg.duration || 5000);

    return function closeManually() {
      clearTimeout(hideTimeout);
      close();
    };
  }

  success(content: string | React.ReactNode, duration?: number) {
    const id = `${Math.random()}`;
    const msg = {
      id,
      type: "success",
      content,
      duration,
    };
    return this._addMsg(msg as Message);
  }

  error(content: string | React.ReactNode, duration?: number) {
    const id = `${Math.random()}`;
    const msg = {
      id,
      type: "error",
      content,
      duration,
    };
    return this._addMsg(msg as Message);
  }

  warning(content: string | React.ReactNode, duration?: number) {
    const id = `${Math.random()}`;
    const msg = {
      id,
      type: "warning",
      content,
      duration,
    };
    return this._addMsg(msg as Message);
  }

  info(content: string | React.ReactNode, duration?: number) {
    const id = `${Math.random()}`;
    const msg = {
      id,
      type: "info",
      content,
      duration,
    };
    return this._addMsg(msg as Message);
  }

  render() {
    const { open, msgs } = this.state;
    return (
      <ThemeProvider theme={globalTheme}>
        <Snackbar open={open}>
          <div>
            {msgs.map((msg) => (
              <Alert key={msg.id} severity={msg.type}>
                {msg.content}
              </Alert>
            ))}
          </div>
        </Snackbar>
      </ThemeProvider>
    );
  }
}

let messageInstance: any;

function init() {
  if (messageInstance) {
    return messageInstance;
  }
  const component = <MessageComponent />;

  const container = document.createElement("div");
  document.body.appendChild(container);
  const newMessageInstance = render(component, container);
  messageInstance = newMessageInstance;
  return messageInstance;
}

export default {
  success: function (content: string | React.ReactNode, duration?: number) {
    const message = init();
    return message.success(content, duration);
  },
  error: function (content: string | React.ReactNode, duration?: number) {
    const message = init();
    return message.error(content, duration);
  },
  warning: function (content: string | React.ReactNode, duration?: number) {
    const message = init();
    return message.warning(content, duration);
  },
  info: function (content: string | React.ReactNode, duration?: number) {
    const message = init();
    return message.info(content, duration);
  },
};
