import { useRef, useState, useCallback } from "react";

export const useControlled = ({ controlled, default: defaultProp }: any) => {
  const { current: isControlled } = useRef(controlled !== undefined);
  const [valueState, setValue] = useState(defaultProp);
  const value = isControlled ? controlled : valueState;
  const setValueIfUncontrolled = useCallback((v) => {
    if (!isControlled) {
      setValue(v);
    }
  }, []);
  return [value, setValueIfUncontrolled];
};
