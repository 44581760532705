const { createMuiTheme } = require("@material-ui/core/styles");

const theme = createMuiTheme({
  palette: {
    primary: {
      default: "#5BC2A6",
      main: "#3C5BFF",
      // dark: '#00001E',
      light: "#d8dcfd",
      background: "#E0E0E0",
      border: "#3C5BFF",
      contrastText: "#fff",
    },
    secondary: {
      main: "#5A96E0",
      contrastText: "#fff",
      border: "#5A96E0",
    },
    action: {
      main: "#E0E0E0",
      disabledBackground: "rgba(0, 0, 0, 0.12)",
      hover: "rgba(0, 0, 0, 0.04)",
      selected: "rgba(0, 0, 0, 0.08)",
      disabledText: "rgba(0, 0, 0, 0.26)",
    },
    other: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      border: "rgba(0, 0, 0, 0.23)",
      divider: "#E0E0E0",
      backdrop: "rgba(0, 0, 0, 0.5)",
      snackbar: "#323232",
    },
    error: {
      main: "#F44336",
      dark: "#E31B0C",
    },
  },
  typography: {
    fontFamily: ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(","),
  },
});

export default theme;
