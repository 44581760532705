import React, { useCallback, useRef, useState } from "react";
import clsx from "clsx";
import { Button, makeStyles } from "@material-ui/core";

import { ButtonGroupsProps, ControlledProps } from "./interface";

export type { ButtonGroupsProps };

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 4,
    margin: theme.spacing(1),
    border: `1px solid ${theme.palette.primary.main}`,
  },
  button: {
    color: theme.palette.text.secondary,
    textTransform: "none",
    "&:not(:last-child)": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      borderRight: `1px solid ${theme.palette.primary.main}`,
    },
    "&:not(:first-child)": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    "&:hover": {
      background: theme.palette.background.default,
    },
  },
  selected: {
    background: theme.palette.primary.main,
    color: "#fff",
    "&:hover": {
      background: theme.palette.primary.main,
    },
  },
}));

const useControlled = ({
  controlled,
  default: defaultProp,
}: ControlledProps) => {
  const { current: isControlled } = useRef(controlled !== undefined);
  const [value, setValue] = useState(defaultProp);
  const setValueIfUncontrolled = useCallback((v) => {
    if (isControlled) {
      setValue(v);
    }
  }, []);
  return [value, setValueIfUncontrolled];
};

const ButtonGroups: React.FC<ButtonGroupsProps> = (props) => {
  const { value: valueProp, options, minWidth, onChange } = props;
  const classes = useStyles();
  const [value, setValue] = useControlled({
    controlled: valueProp,
    default: valueProp,
  });

  function handleChange(value: string | number) {
    setValue(value);
    onChange && onChange(value);
  }

  return (
    <div className={classes.root}>
      {options.map((option) => (
        <Button
          size="small"
          key={option.value}
          onClick={() => handleChange(option.value)}
          className={clsx(
            classes.button,
            option.value === value ? classes.selected : null
          )}
          style={{ minWidth: minWidth === undefined ? "" : minWidth }}
        >
          <span>{option.label}</span>
        </Button>
      ))}
    </div>
  );
};

export default ButtonGroups;
