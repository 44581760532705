import React, { useMemo } from "react";

import { ColumnType } from "./interface";

type CellType = {
  node: ColumnType<any>;
  record: any;
  index: number;
};

const Cell: React.FC<CellType> = ({ node, record, index }) => {
  const childNode: any = useMemo(() => {
    if (typeof record !== "object" || record === null) {
      return "";
    }
    let childNode;
    let value = "";
    if (node.render) {
      if (node.dataIndex) {
        value = record[node.dataIndex];
      }
      childNode = node.render(value, record, index);
    } else if (node.dataIndex) {
      value = record[node.dataIndex];
      childNode = value;
    }
    return childNode || "";
  }, [node, record, index]);

  return childNode;
};

export default Cell;
