
    import React from 'react';
    import { dynamic } from 'dumi';
    import { Link, AnchorLink } from 'dumi/theme';
    import API from 'dumi-theme-default/es/builtins/API.js';
import Alert from 'dumi-theme-default/es/builtins/Alert.js';
import Badge from 'dumi-theme-default/es/builtins/Badge.js';
import Example from 'dumi-theme-default/es/builtins/Example.js';
import Previewer from 'dumi-theme-default/es/builtins/Previewer.js';
import SourceCode from 'dumi-theme-default/es/builtins/SourceCode.js';
    import DUMI_ALL_DEMOS from '@@/dumi/demos';

    const DumiDemo1 = React.memo(DUMI_ALL_DEMOS['modal-demo'].component);

    export default (props) => {
      // scroll to anchor after page component loaded
      React.useEffect(() => {
        if (props?.location?.hash) {
          AnchorLink.scrollToAnchor(decodeURIComponent(props.location.hash.slice(1)));
        }
      }, []);

      return (
        <>
          
          <React.Fragment><div className="markdown"><h2 id="modal"><AnchorLink to="#modal" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>Modal</h2>
<p>Modal</p>
<h3 id="何时使用"><AnchorLink to="#何时使用" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>何时使用</h3>
<ul>
<li>需要展示 Modal</li>
</ul>
<h3 id="代码演示"><AnchorLink to="#代码演示" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>代码演示</h3>
<p>简单使用：</p>
</div><Previewer {...DUMI_ALL_DEMOS['modal-demo'].previewerProps}><DumiDemo1 /></Previewer>
<div className="markdown"><h3 id="api"><AnchorLink to="#api" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>API</h3>
<h5 id="modal-1"><AnchorLink to="#modal-1" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>Modal</h5>

















































































































<table><thead><tr><th>参数</th><th>说明</th><th>类型</th><th>默认值</th></tr></thead><tbody><tr><td>open</td><td>控制 Modal 打开和关闭</td><td>boolean</td><td>-</td></tr><tr><td>title</td><td>标题</td><td>string</td><td>-</td></tr><tr><td>fullWidth</td><td /><td>boolean</td><td>-</td></tr><tr><td>maxWidth</td><td /><td>sm | md | lg | xs | xl</td><td>-</td></tr><tr><td>extraText</td><td /><td>string</td><td>-</td></tr><tr><td>extraInfo</td><td /><td>React.ReactNode</td><td>-</td></tr><tr><td>hideCancel</td><td /><td>boolean</td><td>-</td></tr><tr><td>hideActions</td><td /><td>boolean</td><td>-</td></tr><tr><td>okText</td><td /><td>string</td><td>-</td></tr><tr><td>cancelText</td><td /><td>string</td><td>-</td></tr><tr><td>clearText</td><td /><td>string</td><td>-</td></tr><tr><td>content</td><td /><td>React.ReactNode</td><td>-</td></tr><tr><td>dividers</td><td /><td>boolean</td><td>-</td></tr><tr><td>dialogClasses</td><td /><td>Record&lt; string, React.CSSProperties &gt;</td><td>-</td></tr><tr><td>onClose</td><td /><td>() =&gt; void</td><td>-</td></tr><tr><td>onConfirm</td><td /><td>() =&gt; void</td><td>-</td></tr><tr><td>onClear</td><td /><td>() =&gt; void</td><td>-</td></tr></tbody></table></div></React.Fragment>
        </>
      );
  }