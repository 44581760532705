import React, { useRef, useState, useEffect, useMemo } from "react";
import { TextField, makeStyles, Popover } from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import ArrowDropIcon from "@material-ui/icons/ArrowDropDown";
import MaskedInput from "react-text-mask";
import List from "./List";
import moment, { Moment } from "moment";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: 16,
  },
  dropdown: {
    cursor: "pointer",
  },
}));

interface TimeListProps {
  label?: string;
  value: Moment | null;
  options: Array<{ label: string; value: Moment; disabled: boolean }>;
  onChange: (v: Moment | null) => void;
}

function TextMaskCustom(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, ":", /\d/, /\d/, " ", /[A|P|a|p]/, /[M|m]/]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

function getTime(hour: number, minutes: number) {
  return `${String(hour >= 12 ? hour - 12 : hour).padStart(2, "0")}:${String(
    minutes
  ).padStart(2, "0")} ${hour >= 12 ? "PM" : "AM"}`;
}

const TimeList: React.FC<TimeListProps> = ({
  label,
  options,
  value: inputValue,
  onChange,
}) => {
  const classes = useStyles();
  const textRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(inputValue || null);
  const [errMsg, setErrMsg] = useState("");

  const [, refresh] = useState(1);

  useEffect(() => {
    refresh(0);
  }, []);

  const textValue = useMemo(() => {
    return value ? getTime(value.hours(), value.minutes()) : "";
  }, [value]);

  function handleChange(v: any) {
    setOpen(false);
    setValue(v.value);
    onChange && onChange(v.value);
  }

  function handleBlur(e: any) {
    const value = e.target.value;
    if (value.replace(/:/, "").replace(/\s+/, "") === "") {
      onChange && onChange(null);
      return;
    }
    const times = value.split(" ");
    const hour = parseInt(times[0].split(":")[0]);
    const minute = parseInt(times[0].split(":")[1]);
    if (
      !Number.isFinite(hour) ||
      !Number.isFinite(minute) ||
      hour >= 24 ||
      minute >= 60
    ) {
      setErrMsg("Invalid Date");
      onChange && onChange(null);
      return;
    }
    const day = String(times[1]).toUpperCase();
    const now = moment();
    now.hours(hour > 11 ? hour : day === "PM" ? hour + 12 : hour);
    now.minutes(minute);
    setValue(now);
    onChange && onChange(now);
  }

  return (
    <>
      <TextField
        label={label}
        variant="outlined"
        size="small"
        ref={textRef}
        value={textValue}
        onBlur={handleBlur}
        error={Boolean(errMsg)}
        onChange={() => setErrMsg("")}
        helperText={errMsg}
        InputProps={{
          inputComponent: TextMaskCustom,
          startAdornment: (
            <AccessTimeIcon
              fontSize="small"
              color="action"
              className={classes.icon}
            />
          ),
          endAdornment: (
            <ArrowDropIcon
              color="action"
              onClick={() => setOpen(true)}
              className={classes.dropdown}
            />
          ),
        }}
      />
      {open && textRef && textRef.current && (
        <Popover
          open={true}
          anchorEl={textRef.current}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          onClose={() => setOpen(false)}
        >
          {options.length === 0 ? (
            "No Data"
          ) : (
            <List
              options={options as any}
              // selected={selected ? selected.value : ""}
              onChange={handleChange}
            />
          )}
        </Popover>
      )}
    </>
  );
};

export default TimeList;
