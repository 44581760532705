import { createContext } from "react";
import { Key, EventDataNode } from "./interface";

export type NodeMouseEventHandler<T = HTMLDivElement> = (
  e: React.MouseEvent<T>,
  node: EventDataNode
) => void;

interface MenuContextProps {
  expandedKeys: Key[];
  hoveredMenuKey: string | null;
  onNodeExpand: NodeMouseEventHandler;
  onLeafClick: NodeMouseEventHandler;
  setHoveredMenuKey: (key: string | null) => void;
}

const MenuContext = createContext<MenuContextProps>({} as MenuContextProps);

export default MenuContext;
