import React, { useState, useCallback } from "react";
import moment, { Moment } from "moment";
import { Grid, Divider, Button, makeStyles } from "@material-ui/core";

import Month from "./Month";
import CalendarHeader from "./CalendarHeader";
import TimeRange from "./TimeRange";

const useStyles = makeStyles((theme) => ({
  calendar: {
    padding: theme.spacing(0, 1, 1.5),
  },
  footer: {
    padding: theme.spacing(2),
  },
}));

interface CalendarProps {
  value: [Moment | null, Moment | null] | null[];
  timeDisable?: boolean;
  rangeValid?: [Moment | null, Moment | null];
  onChange: (v: [Moment | null, Moment | null], h: boolean) => void;
  onClose: () => void;
  hasTime?: boolean;
}

const Calendar: React.FC<CalendarProps> = ({
  value,
  timeDisable,
  rangeValid,
  onChange,
  onClose,
  hasTime,
}) => {
  const classes = useStyles();
  const [currentMonth, setCurrentMonth] = useState<Moment>(
    value ? (value[1] === null ? value[0] || moment() : value[1]) : moment()
  );
  const [startDate, setStartDate] = useState<Moment | null>(
    value ? value[0] : null
  );
  const [endDate, setEndDate] = useState<Moment | null>(
    value ? value[1] : null
  );
  const [timeRange, setTimeRange] = useState<Array<null | Moment>>([
    hasTime && value ? value[0] : null,
    hasTime && value ? value[1] : null,
  ]);

  const handleChangeMonth = useCallback((newMonth) => {
    setCurrentMonth(newMonth);
  }, []);

  function handleClickDay(day: Moment) {
    if (!startDate && !endDate) {
      setStartDate(day);
    } else if (startDate && !endDate) {
      if (startDate.isAfter(day)) {
        setStartDate(day);
      } else {
        setEndDate(day);
      }
    } else {
      if (startDate?.isAfter(day) || endDate?.isBefore(day)) {
        setStartDate(day);
        setEndDate(null);
      } else {
        setStartDate(day);
      }
    }
  }

  const onTimeChange = (prop: string) => (value: Moment | null) => {
    if (prop === "startDateTime") {
      setTimeRange([value, timeRange[1]]);
    } else if (prop === "endDateTime") {
      setTimeRange([timeRange[0], value]);
    }
  };

  function handleTimeClear() {
    setTimeRange([null, null]);
  }

  function handleClear() {
    setStartDate(null);
    setEndDate(null);
    setTimeRange([null, null]);
  }

  function handleConfirm() {
    const hasTime = !!(timeRange[0] || timeRange[1]);
    let start = null;
    let end = null;
    if (startDate && endDate) {
      start = startDate.clone();
      end = endDate.clone();
    } else if (startDate && !endDate) {
      start = startDate.clone();
      end = startDate.clone();
    } else {
      start = moment();
      end = moment();
    }
    if (start && end) {
      if (timeRange[0] === null) {
        start = start.startOf("day");
      } else {
        start.hours(timeRange[0].hours());
        start.minutes(timeRange[0].minutes());
        start.seconds(0);
      }
      if (timeRange[1] === null) {
        end = end.endOf("day");
      } else {
        end.hours(timeRange[1].hours());
        end.minutes(timeRange[1].minutes());
        end.seconds(59);
      }
    }
    onChange && onChange([start, end], hasTime);
  }

  return (
    <Grid>
      <CalendarHeader
        month={currentMonth}
        startDate={startDate}
        endDate={endDate}
        onChange={handleChangeMonth}
      />
      <Grid container className={classes.calendar}>
        {Array(2)
          .fill(null)
          .map((_, idx) => {
            const month = currentMonth.clone().subtract(1 - idx, "months");
            return (
              <Grid key={idx} item>
                <Month
                  month={month}
                  rangeValid={rangeValid}
                  startDate={startDate}
                  endDate={endDate}
                  handleClickDay={handleClickDay}
                />
              </Grid>
            );
          })}
      </Grid>
      {!timeDisable && (
        <>
          <Divider />
          <TimeRange
            isOneDay={
              !startDate ||
              !endDate ||
              startDate.format("YYYYMMDD") === endDate.format("YYYYMMDD")
            }
            timeRange={timeRange}
            onChange={onTimeChange}
            onClear={handleTimeClear}
          />
        </>
      )}
      <Divider />
      <Grid container justify="space-between" className={classes.footer}>
        <Grid>
          <Button color="primary" onClick={handleClear}>
            Clear
          </Button>
        </Grid>
        <Grid>
          <Button color="primary" style={{ marginRight: 8 }} onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={handleConfirm}>
            Apply
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Calendar;
