import React, { useRef, useEffect, useState } from "react";
import "viewerjs/dist/viewer.css";
import { makeStyles, createStyles } from "@material-ui/core";
import Viewer from "viewerjs";

export type ImageViewerProps = {
  url: string;
  style?: React.CSSProperties;
  imgAttrs?: Record<string, any>;
};

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      position: "relative",
      top: "0",
      left: "0",
    },
    image: {
      width: 56,
      height: 80,
      verticalAlign: "middle",
      objectFit: "cover",
    },
  })
);

const ImageViewer: React.FC<ImageViewerProps> = ({
  url,
  style,
  imgAttrs,
}: ImageViewerProps) => {
  const classes = useStyles();
  const imageDom = useRef<HTMLImageElement>(null);
  const viewerObj = useRef<Viewer | null>(null);

  const zoomIn = () => {
    viewerObj.current?.show();
  };

  useEffect(() => {
    viewerObj.current = new Viewer(imageDom.current as HTMLImageElement, {
      toolbar: false,
      navbar: false,
      title: false,
    });

    return () => {
      viewerObj.current?.destroy();
      viewerObj.current = null;
    };
  }, []);

  return (
    <div className={classes.container}>
      <img
        src={url}
        className={classes.image}
        ref={imageDom}
        alt=""
        onClick={zoomIn}
        style={style}
        {...imgAttrs}
      />
    </div>
  );
};

export default ImageViewer;
