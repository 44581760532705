import React, { useEffect, useRef } from "react";

import WebRtcPlayer from "./sdk";
import "./index.css";

const containerStyle: React.CSSProperties = {
  position: "absolute",
  top: "0",
  width: "100%",
  height: "100%",
};

export interface WebrtcPlayerProps {
  autoplay?: boolean;
  muted?: boolean;
  url: string;
  onCanPlay?: Function;
  onError?: Function;
  onTimeUpdate?: Function;
  setLoading?: Function;
}

interface WebrtcPlayerType {
  paused: boolean | undefined;
  muted: boolean;
  url: string;
  autoplay: boolean;
  setLoading: (loading: boolean) => void;
  onCanPlay: () => void;
  onError: (
    err: string,
    errData: any,
    _: any,
    hls: any,
    needRetry?: boolean
  ) => void;
  onTimeUpdate: (sencods: number) => void;
}

const WebrtcPlayer: React.FC<WebrtcPlayerProps> = ({
  muted,
  url,
  autoplay,
  onCanPlay,
  onError,
  onTimeUpdate,
  setLoading,
}: WebrtcPlayerType) => {
  const player = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const sdk = new WebRtcPlayer();
    if (url) {
      setLoading && setLoading(true);
      if (player.current) {
        player.current.srcObject = sdk.stream;
      }

      if (onTimeUpdate) {
        player.current?.addEventListener("timeupdate", function () {
          onTimeUpdate(player.current?.currentTime as number);
        });
      }
      sdk
        .play(url)
        .catch((e) => sdk.close())
        .finally(() => setLoading && setLoading(false));
    }
    return () => {
      sdk.close();
    };
  }, [url]);

  function handleCanPlay() {
    console.debug("webrtc video can play");
    onCanPlay && onCanPlay();
  }

  return (
    <div style={containerStyle}>
      <video
        className="video-player webrtc"
        ref={player}
        controls={false}
        autoPlay={autoplay}
        muted={muted}
        onCanPlay={handleCanPlay}
      >
        Live streaming not supported by browser. Please open with Chrome.
      </video>
    </div>
  );
};

export default WebrtcPlayer;
