import React from "react";
import clsx from "clsx";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { useControlled } from "../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    border: "1px solid #eee",
    background: "#eee",
    borderRadius: theme.spacing(2),
    padding: 1,
    boxSizing: "border-box",
    userSelect: "none",
  },
  item: {
    height: 28,
    boxSizing: "border-box",
    lineHeight: "28px",
    padding: "0 10px",
    textAlign: "center",
    flex: 1,
  },
  selected: {
    background: "white",
    borderRadius: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
    boxShadow:
      "0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2)",
  },
}));

export interface SwitchButtonsProps {
  value?: string;
  options: Array<{ label: string; value: string }>;
  onChange?: (v: string) => void;
}

const SwitchButtons: React.FC<SwitchButtonsProps> = ({
  value: inputValue,
  options,
  onChange,
}: SwitchButtonsProps) => {
  const classes = useStyles();
  const [value, setValue] = useControlled({
    controlled: inputValue,
    default: options && options.length && options[0].value,
  });

  function handleChange(v: string) {
    if (v === value) return;
    onChange && onChange(v);
    setValue(v);
  }

  return (
    <Grid className={classes.root} container>
      {options.map((item) => (
        <Typography
          key={item.value}
          onClick={() => handleChange(item.value)}
          variant="body2"
          className={clsx(
            classes.item,
            item.value === value ? classes.selected : ""
          )}
        >
          {item.label}
        </Typography>
      ))}
    </Grid>
  );
};

export default SwitchButtons;
