// @ts-nocheck
import React from 'react';
import { ApplyPluginsType } from '/turing-ui/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';

export function getRoutes() {
  const routes = [
  {
    "path": "/~demos/:uuid",
    "layout": false,
    "wrappers": [require('/turing-ui/node_modules/@umijs/preset-dumi/lib/theme/layout').default],
    "component": (props) => {
        const { default: getDemoRenderArgs } = require('/turing-ui/node_modules/@umijs/preset-dumi/lib/plugins/features/demo/getDemoRenderArgs');
        const { default: Previewer } = require('dumi-theme-default/es/builtins/Previewer.js');
        const { default: demos } = require('@@/dumi/demos');
        const { usePrefersColor } = require('dumi/theme');

        
      const renderArgs = getDemoRenderArgs(props, demos);

      // for listen prefers-color-schema media change in demo single route
      usePrefersColor();

      switch (renderArgs.length) {
        case 1:
          // render demo directly
          return renderArgs[0];

        case 2:
          // render demo with previewer
          return React.createElement(
            Previewer,
            renderArgs[0],
            renderArgs[1],
          );

        default:
          return `Demo ${props.match.params.uuid} not found :(`;
      }
    
        }
  },
  {
    "path": "/_demos/:uuid",
    "redirect": "/~demos/:uuid"
  },
  {
    "__dumiRoot": true,
    "layout": false,
    "path": "/",
    "wrappers": [require('/turing-ui/node_modules/@umijs/preset-dumi/lib/theme/layout').default, require('/turing-ui/node_modules/dumi-theme-default/es/layout.js').default],
    "routes": [
      {
        "path": "/components/c/auto-complete",
        "component": require('/turing-ui/src/auto-complete/index.md').default,
        "exact": true,
        "meta": {
          "filePath": "src/auto-complete/index.md",
          "updatedTime": 1635751129000,
          "componentName": "auto-complete",
          "title": "Autocomplete",
          "order": 2,
          "group": {
            "title": "组件",
            "path": "/components/c"
          },
          "nav": {
            "title": "组件",
            "path": "/components",
            "order": 1
          },
          "slugs": [
            {
              "depth": 2,
              "value": "Autocomplete",
              "heading": "autocomplete"
            },
            {
              "depth": 3,
              "value": "何时使用",
              "heading": "何时使用"
            },
            {
              "depth": 3,
              "value": "代码演示",
              "heading": "代码演示"
            },
            {
              "depth": 3,
              "value": "API",
              "heading": "api"
            },
            {
              "depth": 5,
              "value": "Autocomplete",
              "heading": "autocomplete-1"
            }
          ]
        },
        "title": "Autocomplete - turing-ui"
      },
      {
        "path": "/components/c/button-groups",
        "component": require('/turing-ui/src/button-groups/index.md').default,
        "exact": true,
        "meta": {
          "filePath": "src/button-groups/index.md",
          "updatedTime": 1635751129000,
          "componentName": "button-groups",
          "title": "ButtonGroups",
          "order": 2,
          "group": {
            "title": "组件",
            "path": "/components/c"
          },
          "nav": {
            "title": "组件",
            "path": "/components",
            "order": 1
          },
          "slugs": [
            {
              "depth": 2,
              "value": "ButtonGroups",
              "heading": "buttongroups"
            },
            {
              "depth": 3,
              "value": "何时使用",
              "heading": "何时使用"
            },
            {
              "depth": 3,
              "value": "代码演示",
              "heading": "代码演示"
            },
            {
              "depth": 3,
              "value": "API",
              "heading": "api"
            },
            {
              "depth": 5,
              "value": "ButtonGroups",
              "heading": "buttongroups-1"
            }
          ]
        },
        "title": "ButtonGroups - turing-ui"
      },
      {
        "path": "/components/c/confirm",
        "component": require('/turing-ui/src/confirm/index.md').default,
        "exact": true,
        "meta": {
          "filePath": "src/confirm/index.md",
          "updatedTime": 1635751129000,
          "componentName": "confirm",
          "title": "Confirm",
          "order": 2,
          "group": {
            "title": "组件",
            "path": "/components/c"
          },
          "nav": {
            "title": "组件",
            "path": "/components",
            "order": 1
          },
          "slugs": [
            {
              "depth": 2,
              "value": "Confirm",
              "heading": "confirm"
            },
            {
              "depth": 3,
              "value": "何时使用",
              "heading": "何时使用"
            },
            {
              "depth": 3,
              "value": "代码演示",
              "heading": "代码演示"
            },
            {
              "depth": 3,
              "value": "API",
              "heading": "api"
            },
            {
              "depth": 5,
              "value": "Confirm",
              "heading": "confirm-1"
            }
          ]
        },
        "title": "Confirm - turing-ui"
      },
      {
        "path": "/components/c/date-range",
        "component": require('/turing-ui/src/date-range/index.md').default,
        "exact": true,
        "meta": {
          "filePath": "src/date-range/index.md",
          "updatedTime": 1635751129000,
          "componentName": "date-range",
          "title": "DateRange",
          "order": 2,
          "group": {
            "title": "组件",
            "path": "/components/c"
          },
          "nav": {
            "title": "组件",
            "path": "/components",
            "order": 1
          },
          "slugs": [
            {
              "depth": 2,
              "value": "DateRange",
              "heading": "daterange"
            },
            {
              "depth": 3,
              "value": "何时使用",
              "heading": "何时使用"
            },
            {
              "depth": 3,
              "value": "代码演示",
              "heading": "代码演示"
            },
            {
              "depth": 3,
              "value": "API",
              "heading": "api"
            },
            {
              "depth": 5,
              "value": "DateRange",
              "heading": "daterange-1"
            }
          ]
        },
        "title": "DateRange - turing-ui"
      },
      {
        "path": "/components/c/empty",
        "component": require('/turing-ui/src/empty/index.md').default,
        "exact": true,
        "meta": {
          "filePath": "src/empty/index.md",
          "updatedTime": 1635751129000,
          "componentName": "empty",
          "title": "Empty",
          "order": 2,
          "group": {
            "title": "组件",
            "path": "/components/c"
          },
          "nav": {
            "title": "组件",
            "path": "/components",
            "order": 1
          },
          "slugs": [
            {
              "depth": 2,
              "value": "Empty",
              "heading": "empty"
            },
            {
              "depth": 3,
              "value": "何时使用",
              "heading": "何时使用"
            },
            {
              "depth": 3,
              "value": "代码演示",
              "heading": "代码演示"
            },
            {
              "depth": 3,
              "value": "API",
              "heading": "api"
            },
            {
              "depth": 5,
              "value": "Empty",
              "heading": "empty-1"
            }
          ]
        },
        "title": "Empty - turing-ui"
      },
      {
        "path": "/components/c/hls-player",
        "component": require('/turing-ui/src/hls-player/index.md').default,
        "exact": true,
        "meta": {
          "filePath": "src/hls-player/index.md",
          "updatedTime": 1635751129000,
          "componentName": "hls-player",
          "title": "HLSPlayer",
          "order": 2,
          "group": {
            "title": "组件",
            "path": "/components/c"
          },
          "nav": {
            "title": "组件",
            "path": "/components",
            "order": 1
          },
          "slugs": [
            {
              "depth": 2,
              "value": "HLSPlayer",
              "heading": "hlsplayer"
            },
            {
              "depth": 3,
              "value": "何时使用",
              "heading": "何时使用"
            },
            {
              "depth": 3,
              "value": "代码演示",
              "heading": "代码演示"
            },
            {
              "depth": 3,
              "value": "API",
              "heading": "api"
            },
            {
              "depth": 5,
              "value": "HLSPlayer",
              "heading": "hlsplayer-1"
            }
          ]
        },
        "title": "HLSPlayer - turing-ui"
      },
      {
        "path": "/components/c/image-viewer",
        "component": require('/turing-ui/src/image-viewer/index.md').default,
        "exact": true,
        "meta": {
          "filePath": "src/image-viewer/index.md",
          "updatedTime": 1635751129000,
          "componentName": "image-viewer",
          "title": "ImageViewer",
          "order": 2,
          "group": {
            "title": "组件",
            "path": "/components/c"
          },
          "nav": {
            "title": "组件",
            "path": "/components",
            "order": 1
          },
          "slugs": [
            {
              "depth": 2,
              "value": "ImageViewer",
              "heading": "imageviewer"
            },
            {
              "depth": 3,
              "value": "何时使用",
              "heading": "何时使用"
            },
            {
              "depth": 3,
              "value": "代码演示",
              "heading": "代码演示"
            },
            {
              "depth": 3,
              "value": "API",
              "heading": "api"
            },
            {
              "depth": 5,
              "value": "ImageViewer",
              "heading": "imageviewer-1"
            }
          ]
        },
        "title": "ImageViewer - turing-ui"
      },
      {
        "path": "/components/c/message",
        "component": require('/turing-ui/src/message/index.md').default,
        "exact": true,
        "meta": {
          "filePath": "src/message/index.md",
          "updatedTime": 1635751129000,
          "componentName": "message",
          "title": "Message",
          "order": 2,
          "group": {
            "title": "组件",
            "path": "/components/c"
          },
          "nav": {
            "title": "组件",
            "path": "/components",
            "order": 1
          },
          "slugs": [
            {
              "depth": 2,
              "value": "Message",
              "heading": "message"
            },
            {
              "depth": 3,
              "value": "何时使用",
              "heading": "何时使用"
            },
            {
              "depth": 3,
              "value": "代码演示",
              "heading": "代码演示"
            },
            {
              "depth": 3,
              "value": "API",
              "heading": "api"
            },
            {
              "depth": 5,
              "value": "Message",
              "heading": "message-1"
            }
          ]
        },
        "title": "Message - turing-ui"
      },
      {
        "path": "/components/c/modal",
        "component": require('/turing-ui/src/modal/index.md').default,
        "exact": true,
        "meta": {
          "filePath": "src/modal/index.md",
          "updatedTime": 1635751129000,
          "componentName": "modal",
          "title": "Modal",
          "order": 2,
          "group": {
            "title": "组件",
            "path": "/components/c"
          },
          "nav": {
            "title": "组件",
            "path": "/components",
            "order": 1
          },
          "slugs": [
            {
              "depth": 2,
              "value": "Modal",
              "heading": "modal"
            },
            {
              "depth": 3,
              "value": "何时使用",
              "heading": "何时使用"
            },
            {
              "depth": 3,
              "value": "代码演示",
              "heading": "代码演示"
            },
            {
              "depth": 3,
              "value": "API",
              "heading": "api"
            },
            {
              "depth": 5,
              "value": "Modal",
              "heading": "modal-1"
            }
          ]
        },
        "title": "Modal - turing-ui"
      },
      {
        "path": "/components/c/pro-autocomplete",
        "component": require('/turing-ui/src/pro-autocomplete/index.md').default,
        "exact": true,
        "meta": {
          "filePath": "src/pro-autocomplete/index.md",
          "updatedTime": 1635751129000,
          "componentName": "pro-autocomplete",
          "title": "ProAutocomplete",
          "order": 2,
          "group": {
            "title": "组件",
            "path": "/components/c"
          },
          "nav": {
            "title": "组件",
            "path": "/components",
            "order": 1
          },
          "slugs": [
            {
              "depth": 2,
              "value": "ProAutocomplete",
              "heading": "proautocomplete"
            },
            {
              "depth": 3,
              "value": "何时使用",
              "heading": "何时使用"
            },
            {
              "depth": 3,
              "value": "代码演示",
              "heading": "代码演示"
            },
            {
              "depth": 3,
              "value": "API",
              "heading": "api"
            },
            {
              "depth": 5,
              "value": "ProAutocomplete",
              "heading": "proautocomplete-1"
            }
          ]
        },
        "title": "ProAutocomplete - turing-ui"
      },
      {
        "path": "/components/c/pro-select",
        "component": require('/turing-ui/src/pro-select/index.md').default,
        "exact": true,
        "meta": {
          "filePath": "src/pro-select/index.md",
          "updatedTime": 1635751129000,
          "componentName": "pro-select",
          "title": "ProSelect",
          "order": 2,
          "group": {
            "title": "组件",
            "path": "/components/c"
          },
          "nav": {
            "title": "组件",
            "path": "/components",
            "order": 1
          },
          "slugs": [
            {
              "depth": 2,
              "value": "ProSelect",
              "heading": "proselect"
            },
            {
              "depth": 3,
              "value": "何时使用",
              "heading": "何时使用"
            },
            {
              "depth": 3,
              "value": "代码演示",
              "heading": "代码演示"
            },
            {
              "depth": 3,
              "value": "API",
              "heading": "api"
            },
            {
              "depth": 5,
              "value": "ProSelect",
              "heading": "proselect-1"
            }
          ]
        },
        "title": "ProSelect - turing-ui"
      },
      {
        "path": "/components/c/select",
        "component": require('/turing-ui/src/select/index.md').default,
        "exact": true,
        "meta": {
          "filePath": "src/select/index.md",
          "updatedTime": 1635751129000,
          "componentName": "select",
          "title": "Select",
          "order": 2,
          "group": {
            "title": "组件",
            "path": "/components/c"
          },
          "nav": {
            "title": "组件",
            "path": "/components",
            "order": 1
          },
          "slugs": [
            {
              "depth": 2,
              "value": "Select",
              "heading": "select"
            },
            {
              "depth": 3,
              "value": "何时使用",
              "heading": "何时使用"
            },
            {
              "depth": 3,
              "value": "代码演示",
              "heading": "代码演示"
            },
            {
              "depth": 3,
              "value": "API",
              "heading": "api"
            },
            {
              "depth": 5,
              "value": "Select",
              "heading": "select-1"
            }
          ]
        },
        "title": "Select - turing-ui"
      },
      {
        "path": "/components/c/switch-buttons",
        "component": require('/turing-ui/src/switch-buttons/index.md').default,
        "exact": true,
        "meta": {
          "filePath": "src/switch-buttons/index.md",
          "updatedTime": 1635751129000,
          "componentName": "switch-buttons",
          "title": "SwitchButtons",
          "order": 5,
          "group": {
            "title": "组件",
            "path": "/components/c"
          },
          "nav": {
            "title": "组件",
            "path": "/components",
            "order": 1
          },
          "slugs": [
            {
              "depth": 2,
              "value": "SwitchButtons",
              "heading": "switchbuttons"
            },
            {
              "depth": 3,
              "value": "何时使用",
              "heading": "何时使用"
            },
            {
              "depth": 3,
              "value": "代码演示",
              "heading": "代码演示"
            },
            {
              "depth": 3,
              "value": "API",
              "heading": "api"
            },
            {
              "depth": 5,
              "value": "SwitchButtons",
              "heading": "switchbuttons-1"
            }
          ]
        },
        "title": "SwitchButtons - turing-ui"
      },
      {
        "path": "/components/c/table",
        "component": require('/turing-ui/src/table/index.md').default,
        "exact": true,
        "meta": {
          "filePath": "src/table/index.md",
          "updatedTime": 1635751129000,
          "componentName": "table",
          "title": "Table",
          "order": 2,
          "group": {
            "title": "组件",
            "path": "/components/c"
          },
          "nav": {
            "title": "组件",
            "path": "/components",
            "order": 1
          },
          "slugs": [
            {
              "depth": 2,
              "value": "Table",
              "heading": "table"
            },
            {
              "depth": 3,
              "value": "何时使用",
              "heading": "何时使用"
            },
            {
              "depth": 3,
              "value": "代码演示",
              "heading": "代码演示"
            },
            {
              "depth": 3,
              "value": "API",
              "heading": "api"
            },
            {
              "depth": 5,
              "value": "Table",
              "heading": "table-1"
            },
            {
              "depth": 5,
              "value": "ColumnType",
              "heading": "columntype"
            }
          ]
        },
        "title": "Table - turing-ui"
      },
      {
        "path": "/components/c/tooltip",
        "component": require('/turing-ui/src/tooltip/index.md').default,
        "exact": true,
        "meta": {
          "filePath": "src/tooltip/index.md",
          "updatedTime": 1635751129000,
          "componentName": "tooltip",
          "title": "Tooltip",
          "order": 2,
          "group": {
            "title": "组件",
            "path": "/components/c"
          },
          "nav": {
            "title": "组件",
            "path": "/components",
            "order": 1
          },
          "slugs": [
            {
              "depth": 2,
              "value": "Tooltip",
              "heading": "tooltip"
            },
            {
              "depth": 3,
              "value": "何时使用",
              "heading": "何时使用"
            },
            {
              "depth": 3,
              "value": "代码演示",
              "heading": "代码演示"
            },
            {
              "depth": 3,
              "value": "API",
              "heading": "api"
            },
            {
              "depth": 5,
              "value": "Tooltip",
              "heading": "tooltip-1"
            }
          ]
        },
        "title": "Tooltip - turing-ui"
      },
      {
        "path": "/components/c/webrtc-player",
        "component": require('/turing-ui/src/webrtc-player/index.md').default,
        "exact": true,
        "meta": {
          "filePath": "src/webrtc-player/index.md",
          "updatedTime": 1635751129000,
          "componentName": "webrtc-player",
          "title": "WebrtcPlayer",
          "order": 2,
          "group": {
            "title": "组件",
            "path": "/components/c"
          },
          "nav": {
            "title": "组件",
            "path": "/components",
            "order": 1
          },
          "slugs": [
            {
              "depth": 2,
              "value": "WebrtcPlayer",
              "heading": "webrtcplayer"
            },
            {
              "depth": 3,
              "value": "何时使用",
              "heading": "何时使用"
            },
            {
              "depth": 3,
              "value": "代码演示",
              "heading": "代码演示"
            },
            {
              "depth": 3,
              "value": "API",
              "heading": "api"
            },
            {
              "depth": 5,
              "value": "WebrtcPlayer",
              "heading": "webrtcplayer-1"
            }
          ]
        },
        "title": "WebrtcPlayer - turing-ui"
      },
      {
        "path": "/",
        "component": require('/turing-ui/docs/index.md').default,
        "exact": true,
        "meta": {
          "filePath": "docs/index.md",
          "updatedTime": 1635751129000,
          "hero": {
            "title": "turing-ui",
            "desc": "<div class=\"markdown\"><p>Turing components library for React</p></div>",
            "actions": [
              {
                "text": "Getting Started",
                "link": "/components"
              }
            ]
          },
          "features": [
            {
              "icon": "https://gw.alipayobjects.com/zos/bmw-prod/881dc458-f20b-407b-947a-95104b5ec82b/k79dm8ih_w144_h144.png",
              "title": "Feature 1",
              "desc": "<div class=\"markdown\"><p>Balabala</p></div>"
            },
            {
              "icon": "https://gw.alipayobjects.com/zos/bmw-prod/d60657df-0822-4631-9d7c-e7a869c2f21c/k79dmz3q_w126_h126.png",
              "title": "Feature 2",
              "desc": "<div class=\"markdown\"><p>Balabala</p></div>"
            },
            {
              "icon": "https://gw.alipayobjects.com/zos/bmw-prod/d1ee0c6f-5aed-4a45-a507-339a4bfe076c/k7bjsocq_w144_h144.png",
              "title": "Feature 3",
              "desc": "<div class=\"markdown\"><p>Balabala</p></div>"
            }
          ],
          "footer": "<div class=\"markdown\"><p>Copyright Turing Video© 2021<br /></p></div>",
          "slugs": [],
          "title": "Index"
        },
        "title": "Index - turing-ui"
      },
      {
        "path": "/components/c",
        "meta": {},
        "exact": true,
        "redirect": "/components/c/auto-complete"
      },
      {
        "path": "/components",
        "meta": {
          "order": 1
        },
        "exact": true,
        "redirect": "/components/c/auto-complete"
      }
    ],
    "title": "turing-ui",
    "component": (props) => props.children
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
