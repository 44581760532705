import React from "react";
import { List, ListItem } from "@material-ui/core";
import { OptionType } from "../pro-select/index";

interface ProListProps {
  selected?: string | number;
  options: OptionType[];
  onChange: (v: OptionType) => void;
}

const ProList: React.FC<ProListProps> = ({ options, selected, onChange }) => {
  return (
    <List style={{ maxHeight: 310, width: 227 }}>
      {options.map((item, idx) => (
        <ListItem
          selected={selected === item.value}
          key={idx}
          onClick={() => onChange(item)}
          button
          disabled={item.disabled}
        >
          {item.label}
        </ListItem>
      ))}
    </List>
  );
};

export default ProList;
