import React, { useMemo } from "react";

import { TextField, MenuItem, makeStyles } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { SelectProps, OptionType } from "./interface";

export type { SelectProps, OptionType };

const useStyles = makeStyles(() => ({
  select: {
    background: "white !important",
  },
  textField: {
    cursor: "pointer",
    "&::before": {
      border: "0 !important",
      content: "",
    },
    "&::after": {
      border: "0 !important",
      content: "",
    },
    "& fieldset": {
      visibility: "hidden !important",
    },
  },
}));

const Select: React.FC<SelectProps> = (props) => {
  const {
    options = [],
    value: inputValue,
    size = "medium",
    placeholder = "",
    label = "",
    fullWidth = true,
    freeSolo = false,
    hideAllOption = false,
    filter = true,
    noBorder = false,
    startAdornment,
    disableClearable,
    classes,
    className,
    style,
    renderOption,
    onChange,
  } = props;

  const styles = useStyles();

  const memoOptions = useMemo(() => {
    return hideAllOption
      ? options
      : [{ value: "all", label: "All" } as OptionType].concat(options);
  }, [options, hideAllOption]);

  function handleChange(event: React.ChangeEvent<{}>, option: any) {
    onChange &&
      onChange(
        typeof option === "object" && option !== null ? option.value : option
      );
  }

  const memoValue = useMemo(() => {
    if (filter && inputValue !== undefined && inputValue !== null) {
      return memoOptions.find((item) => item.value === inputValue);
    }
    return inputValue;
  }, [inputValue, memoOptions, filter]);

  return filter ? (
    <Autocomplete
      fullWidth={fullWidth}
      size={size}
      clearOnBlur
      disableClearable={disableClearable}
      freeSolo={freeSolo}
      options={options}
      style={style}
      classes={classes}
      className={className}
      value={memoValue as any}
      onChange={handleChange}
      renderOption={renderOption}
      getOptionLabel={(option) => option.label || (option as any)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          style={{ background: "white" }}
          className={noBorder ? styles.textField : ""}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            startAdornment: startAdornment,
          }}
          variant="outlined"
        />
      )}
    />
  ) : (
    <TextField
      fullWidth={fullWidth}
      size={size}
      select
      style={style}
      classes={classes}
      className={`${className} ${noBorder ? styles.textField : ""}`}
      value={memoValue}
      label={label}
      InputProps={{
        startAdornment: startAdornment ? (
          <div style={{ display: "flex", marginLeft: -4, marginRight: 4 }}>
            {startAdornment}
          </div>
        ) : undefined,
      }}
      SelectProps={{
        classes: { select: styles.select },
        MenuProps: {
          style: {
            marginTop: size === "medium" ? 56 : 40,
            marginLeft: startAdornment === undefined ? 0 : -40,
          },
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        },
      }}
      variant="outlined"
      onChange={(e) => {
        onChange && onChange(e.target.value);
      }}
    >
      {memoOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default Select;
